import React from 'react';
import { createRoot } from 'react-dom/client'; 
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { setupAxiosInterceptors } from './redux/authSlice'
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Corrected: Only one root declaration
const container = document.getElementById('root');
const root = createRoot(container);
 // Create the root using React 18 method
setupAxiosInterceptors(store);
root.render(
 
  <Provider store={store}>
     <ToastContainer autoClose={3000} position="top-right" />
    <App />
  </Provider>
  
);

// Removed the second incorrect root declaration

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

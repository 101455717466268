import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50">
      <div className="relative flex flex-col items-center gap-4 bg-white p-6 rounded-lg shadow-xl">
        <div className="w-12 h-12 rounded-full border-4 border-green-500 border-t-transparent animate-spin" />
        <span className="text-gray-700 font-medium">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingSpinner;
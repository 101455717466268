import React from "react";
import { FaRegEdit } from "react-icons/fa";
import Layout from "./common/Layout";
import profilePic from "../assets/profile.png";
import gift from "../assets/gift.png"
import { MdOutlineLockReset } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import prime from "../assets/prime-video.png"
export default function Profile() {
  return (
    <Layout>
      <div className="bg-[#F5F5F5] py-10 px-20">
        <div className="container mx-auto">
          <div className="flex gap-6">
            <div className="w-[20%]">
              <h3 className="text-xl font-semibold">Profile</h3>
              <p className="text-sm font-normal text-gray-600">
                You have 2 items in your cart
              </p>
              <div className="mt-5 bg-white rounded-lg p-4">
                <div className="flex justify-end">
                  <a href="/edit-profile">
                  <button className="flex gap-1 text-[#46A3F9] bg-transparent">
                    {" "}
                    <FaRegEdit className="mt-1" /> Edit
                  </button>
                  </a>
                </div>
                <div className="text-center">
                  <img src={profilePic} className="m-auto" />
                  <p className="text-lg font-semibold">John Doe</p>
                  <p className="text-sm font-normal text-gray-600">
                    johndoe@gmail.com
                  </p>
                </div>
                <div className="border-t border-gray-300 py-6 mt-4">
                    <div>
                        <p className="text-gray-700 text-md">Mobile Number</p>
                        <p className="font-semibold text-lg">+91 1234 5678 90</p>
                    </div>
                    <div className="mt-3">
                        <p className="text-gray-700 text-md">Address</p>
                        <p className="text-gray-700 text-sm mt-2">Home</p>
                        
                        <p className="font-semibold text-lg">Apt. 986 24484 Pollich Ways, Gleichnerfort, MS 48836</p>
                    </div>
                    <button className=" mt-2 bg-transparent font-medium text-[#46A3F9]">+ Add Address</button>
                </div>
                <div className="gift-card flex flex-col rounded-2xl gap-4 p-3">
                    <div className="flex gap-4">
                        <img src={gift} />
                        <div>
                            <p className="text-3xl font-bold text-center">2054</p>
                            <p className="text-xl font-normal text-center">Points</p>
                        </div>
                    </div>
                    <button className="text-white font-medium bg-[#5BD318] rounded-full w-full py-2">Use Now</button>
                </div>
              </div>
              <div className="mt-5 bg-white rounded-lg p-4">
                <div className="flex gap-2">
                    <MdOutlineLockReset className="text-xl mt-1"/>
                    <p className="font-medium">Change Password</p>
                </div>
              </div>
              <div className="mt-5 bg-white rounded-lg p-4">
                <div className="flex gap-2">
                    <MdLogout className="text-xl mt-1"/>
                    <p className="font-medium text-[#E86A43]">Logout</p>
                </div>
              </div>
            </div>
            <div className="grow">
                <h3 className="text-xl font-semibold">Orders</h3>
              <p className="text-sm font-normal text-gray-600">
                You have 2 items in your cart
              </p>
              <div className="mt-5 bg-white rounded-lg p-6">
                <h2 className="font-bold">Delivered</h2>
                <div className="orders flex flex-col gap-6 mt-5">
                    <div className="flex justify-between">
                        <div className="flex gap-3">
                            <img src={prime} className="" />
                            <div>
                                <p className="font-semibold text-lg">Sony Liv 12 Month Subscription</p>
                                <p className="text-gray-500 text-md mt-2">Voucher</p>
                                <p className="font-normal text-lg mt-2">15 July 2024 at 02:25 PM</p>
                                <p className="text-[#5BD318] font-medium text-lg mt-2">1499 Pts.</p>
                            </div>
                        </div>
                        <div className="mt-8">
                            <div className="flex flex-col gap-5">
                                <button className="bg-transparent border border-[#5BD318] rounded py-2 px-8">View Order Details</button>
                                <button className="bg-transparent font-semibold text-[#46A3F9] rounded py-2 px-3">Get Invoice</button>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="flex gap-3">
                            <img src={prime} className="" />
                            <div>
                                <p className="font-semibold text-lg">Sony Liv 12 Month Subscription</p>
                                <p className="text-gray-500 text-md mt-2">Voucher</p>
                                <p className="font-normal text-lg mt-2">15 July 2024 at 02:25 PM</p>
                                <p className="text-[#5BD318] font-medium text-lg mt-2">1499 Pts.</p>
                            </div>
                        </div>
                        <div className="mt-8">
                            <div className="flex flex-col gap-5">
                                <button className="bg-transparent border border-[#5BD318] rounded py-2 px-8">View Order Details</button>
                                <button className="bg-transparent font-semibold text-[#46A3F9] rounded py-2 px-3">Get Invoice</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 bg-[#E7F3FF] rounded py-2 w-full text-center">
                    <p>Gift cards are delivered digitally to your contact information. Cards once issued cannot be cancelled.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import React, { useState } from "react";
import othersImg from "../../assets/others.png";
import check from "../../assets/gg_check.svg";
import paytmImg from "../../assets/paytm.png";
import gPayImg from "../../assets/gpay.png";
import phonePayImg from "../../assets/phone-pay.png";
import wpImg from "../../assets/wp.png";
import bhimImg from "../../assets/bhim.png";
import joy from "../../assets/voucher/joy.svg";
import voucherImg from "../../assets/voucher/voucher.svg";
import loading from "../../assets/loading.png";
import axiosInstance from "../../axios";
import { Box, Typography, Button, Card } from "@mui/material";
export default function PurchaseVoucher() {
  const options = [
    {
      name: "Bhim",
      desc: "Rorem ipsum dolor sit amet, consectetur",
      imgSrc: bhimImg,
    },
    {
      name: "Google Pay",
      desc: "Rorem ipsum dolor sit amet, consectetur",
      imgSrc: gPayImg,
    },
    {
      name: "PhonePe",
      desc: "Rorem ipsum dolor sit amet, consectetur",
      imgSrc: phonePayImg,
    },
    {
      name: "Paytm",
      desc: "Rorem ipsum dolor sit amet, consectetur",
      imgSrc: paytmImg,
    },
    {
      name: "Whatsapp",
      desc: "Rorem ipsum dolor sit amet, consectetur",
      imgSrc: wpImg,
    },
  ];
  return (
    <Card
      elevation={0}
      sx={{
        boxShadow: "0px 4px 18.8px -5px #50A63B59",
        borderRadius: "25px",
        mt: 4,
        width: "450px",
        mx: "auto",
        "@media (max-width:768px)": {
          width: "90%",
          boxShadow:"none",
          mt:0
        },
      }}
    >
      <Box
        sx={{
          p: 5,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <img src={joy} className="joy-img" style={{ width: "50%", margin: "auto" }} />
        <Typography fontSize={20} fontWeight={600} sx={{
            "@media (max-width:1400px)": {
          fontSize:"15px"
        },
        "@media (max-width:768px)": {
          fontSize:"18px"
        },

        }}>
          Purchase Vouchers and Earn Benefits
        </Typography>
        <Typography fontSize={14} fontWeight={400} color="#728393" sx={{
            "@media (max-width:1400px)": {
          fontSize:"10px"
        },
        "@media (max-width:768px)": {
          fontSize:"14px"
        },
        }}>
          Purchase voucher for any demonination, Earn 3x worth benefits
        </Typography>
        <Box sx={{ height: "2px", borderTop: "1px solid #DEE1E4" }}></Box>
        <Typography fontWeight={500} fontSize={18} sx={{
            "@media (max-width:1400px)": {
          fontSize:"14px"
        },
        "@media (max-width:768px)": {
          fontSize:"16px"
        },
        }}>
          You are purchasing for
        </Typography>
        <Typography fontSize={22} fontWeight={700} sx={{
            "@media (max-width:1400px)": {
          fontSize:"20px"
        },
        "@media (max-width:768px)": {
          fontSize:"25px"
        },
        }}>
          ₹ 1000
        </Typography>
        <Typography fontSize={12} color="#728393">
          Note: Only multiples of 5 are accepted as amount.
        </Typography>
        <Box
          className="voucher-div"
          sx={{
            maxHeight: "100px",
            overflow: "auto",
            px: 1,
            "@media (max-width:768px)": {
            px: "0",
            maxHeight:"250px"
        },
          }}
        >
          <Box
            sx={{
              border: "1px solid #FFCB8375",
              borderRadius: "6px",
              textAlign: "left",
              overflow: "hidden",
              backgroundColor: "#FFF2DF54",
              display: "flex",
              position: "relative",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              mb: "10px",
            }}
          >
            <Box
              sx={{
                zIndex: "1",
                position: "absolute",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: "#FFD495",
                right: "-20px",
                top: "-10px",
              }}
            ></Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <img src={voucherImg} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography fontWeight={600}>Pay ₹ 5</Typography>
                <Typography fontSize={12} color="#728393">
                  Get 10 worth voucher points
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                zIndex: "5",
                width: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "right",
                pr: 1,
              }}
            >
              <Typography fontSize={13} fontWeight={600} color="#F56A50">
                Limited Period Offer
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #FFCB8375",
              borderRadius: "6px",
              textAlign: "left",
              overflow: "hidden",
              backgroundColor: "#FFF2DF54",
              display: "flex",
              position: "relative",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <img src={voucherImg} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography fontWeight={600}>Pay ₹ 100</Typography>
                <Typography fontSize={12}>
                  Get 10 worth voucher points
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
        <Button
          fullWidth
          className="fixed-button"
          sx={{
            background: "linear-gradient(360deg, #26CD18 0%, #74E018 100%)",
            color: "white",
            width:"90%",
            margin:"auto",
            borderRadius:"40px",
            marginTop:"-12px",
          }}
        >
          Next
        </Button>
      </Box>
    </Card>
  );
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';

// Track initialization state
let isInitialized = false;

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, { getState }) => {
    // Check current state
    const state = getState().categories;
    
    // If we already have categories or are initialized, skip the API call
    if (isInitialized || state.categories.length > 0) {
      return { categories: state.categories, subcategories: state.subcategories };
    }

    try {
      const response = await axios.get(`${BASE_URL}/home/`);
     
      const productsData = response.data;

      // Extract and sort categories and subcategories
      const categories = productsData.categories?.sort((a, b) => a.id - b.id) || [];
      const subcategories = productsData.subcategories?.sort((a, b) => a.id - b.id) || [];

      // Mark as initialized after successful fetch
      isInitialized = true;
      
      return { categories, subcategories };
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  },
  {
    // Condition function to prevent unnecessary API calls
    condition: (_, { getState }) => {
      const state = getState().categories;
      // Only fetch if we're not already loading and not initialized
      return state.status !== 'loading' && !isInitialized;
    }
  }
);

const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    subcategories: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Add a reset reducer if needed
    resetCategories: (state) => {
      state.categories = [];
      state.subcategories = [];
      state.status = 'idle';
      state.error = null;
      isInitialized = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        // Only update status if we're not already loaded
        if (state.status === 'idle') {
          state.status = 'loading';
        }
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        // Only update if we got new data and aren't already loaded
        if (state.status === 'loading' || state.categories.length === 0) {
          state.status = 'succeeded';
          state.categories = action.payload.categories;
          state.subcategories = action.payload.subcategories;
        }
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        isInitialized = false; // Reset initialization on error
      
      });
  },
});

// Export reset action if needed
export const { resetCategories } = categorySlice.actions;

// Selector to check if categories are loaded
export const selectCategoriesLoaded = (state) => 
  state.categories.status === 'succeeded' && state.categories.categories.length > 0;

export default categorySlice.reducer;
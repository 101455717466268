import React from "react";
import owlxLogo from "../../assets/owlx-logo.png";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
export default function Footer() {
  return (
    <footer className="bg-[#162533] text-gray-300">
      <div className="bg-[#192C3E] py-4 px-10">
        <div className="container mx-auto">
          <div className="mb-8 md:mb-0 flex justify-between">
            <a
              href="#"
              className="text-white font-bold text-lg flex items-center mb-4"
            >
              <img src={owlxLogo} alt="Owlx Logo" className="h-10 mr-2" />
            </a>
            <div className="social-icons flex gap-4 mt-2">
              <div className="bg-[#1F384F] rounded-full grid place-items-center h-[40px] w-[40px]">
                <FaInstagram className="text-xl font-semibold text-[#5BD318]" />
              </div>
              <div className="bg-[#1F384F] rounded-full grid place-items-center h-[40px] w-[40px]">
                <FaXTwitter className="text-xl font-semibold text-[#5BD318]" />
              </div>
              <div className="bg-[#1F384F] rounded-full grid place-items-center h-[40px] w-[40px]">
                <FaFacebookF className="text-xl font-semibold text-[#5BD318]" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-[#162533] text-gray-300">
    <div className="container mx-auto px-10 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
                <h6 className="text-white text-xl font-semibold mb-4">Head Office</h6>
                <p>No. 259, 1st floor 1st Main, 4th Cross Rd, above A1 Puncture Shop, 3rd Phase, Banashankari 3rd Stage, Bengaluru, Karnataka 560085</p>
            </div>
            <div>
                <h6 className="text-white text-xl font-semibold mb-4">Quick Links</h6>
                <ul className="space-y-2">
                    <li><a href="/" className="hover:text-green-500 transition-colors">Catalog</a></li>
                    <li><a href="/redeem-voucher" className="hover:text-green-500 transition-colors">Load Coins</a></li>
                    <li><a href="/Contact" className="hover:text-green-500 transition-colors">Bulk Enquiry</a></li>
                    <li><a href="/Contact" className="hover:text-green-500 transition-colors">Partner with us</a></li>
                </ul>
            </div>
            <div>
                <h6 className="text-white text-xl font-semibold mb-4">Support</h6>
                <ul className="space-y-2">
                    <li><a href="/help-center" className="hover:text-green-500 transition-colors">Help Center</a></li>
                    <li><a href="/Profile" className="hover:text-green-500 transition-colors">My Account</a></li>
                    <li><a href="/Contact" className="hover:text-green-500 transition-colors">Ticket Support</a></li>
                    <li><a href="/Contact" className="hover:text-green-500 transition-colors">Contact Us</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div className="bg-[#192C3E] py-4 mt-8">
        <div className="container mx-auto text-center md:text-left">
            <p>&copy; OwlX 2024 | All Rights Reserved</p>
            <div className="flex justify-center md:justify-start space-x-4 mt-4">
                <a href="/terms-conditions" className="hover:text-green-500">Terms of Use</a>
                <a href="#" className="hover:text-green-500">Privacy Policy</a>
                <a href="#" className="hover:text-green-500">Cookie Policy</a>
            </div>
        </div>
    </div>
    
</footer>
</footer>
  
    
  );
}

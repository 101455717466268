import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import Cookies from 'js-cookie';


const cookieConfig = {
  expires: 7,
  path: '/',
  secure: false, // Set to true in production
  sameSite: 'Lax',
  domain: window.location.hostname
};

// Initialize axios defaults
axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

// Helper function to set auth headers
const setAuthHeader = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Setup Axios Interceptors
export const setupAxiosInterceptors = (store) => {
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  // Initialize headers from existing token
  const existingToken = Cookies.get('access_token');
  if (existingToken) {
    setAuthHeader(existingToken);
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = Cookies.get('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const refresh_token = Cookies.get('refresh_token');
            if (!refresh_token) {
              store.dispatch(logout());
              throw new Error('No refresh token available');
            }

            const response = await axiosInstance.post('/user/refreshtoken/', {
              refresh_token,
            });

            const newToken = response.data.access_token;
            
            // Set cookie and update headers
            Cookies.set('access_token', newToken, cookieConfig);
            setAuthHeader(newToken);

            // Update store
            store.dispatch(setToken(newToken));
            store.dispatch(setInitialized(true));

            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            processQueue(null, newToken);

            return axiosInstance(originalRequest);
          } catch (refreshError) {
            processQueue(refreshError, null);
            store.dispatch(logout());
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        }

        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        });
      }

      return Promise.reject(error);
    }
  );
};

// Async thunks
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (loginData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/user/login/', loginData);
      
      // Set cookies
      Cookies.set('access_token', response.data.access_token, cookieConfig);
      Cookies.set('refresh_token', response.data.refresh_token, cookieConfig);
      
      // Set auth header
      setAuthHeader(response.data.access_token);
      
      // Initialize the app
      dispatch(setInitialized(true));

      // Fetch user coins after successful login
      dispatch(fetchUserCoins());
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Login failed');
    }
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const refresh_token = Cookies.get('refresh_token');
      if (!refresh_token) throw new Error('No refresh token available');

      const response = await axiosInstance.post('/user/refreshtoken/', {
        refresh_token,
      });
      
      const newToken = response.data.access_token;
      
      // Set cookie and update headers
      Cookies.set('access_token', newToken, cookieConfig);
      setAuthHeader(newToken);
      
      return newToken;
    } catch (error) {
      dispatch(logout());
      return rejectWithValue(error.response?.data || 'Token refresh failed');
    }
  }
);

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/user-data/');
      // After fetching user data, also update coins
      if (response.data) {
        dispatch(fetchUserCoins());
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch user data');
    }
  }
);

// Restored and improved fetchUserCoins thunk
export const fetchUserCoins = createAsyncThunk(
  'auth/fetchUserCoins',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/user-data/');
      if (!response || !response.data) {
        throw new Error('Invalid response from server');
      }
      // Safely handle the response and ensure we return a number
      const userCoins = response.data?.usercoins ?? response.data?.coins ?? 0;
      return Number(userCoins); // Convert to number to ensure consistency
    } catch (error) {
      console.error('Failed to fetch user coins:', error);
      return rejectWithValue(error.response?.data || 'Failed to fetch user coins');
    }
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    usercoins: 0,
    token: Cookies.get('access_token') || null,
    status: 'idle',
    error: null,
    isInitialized: false,
    coinsLoading: false,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      setAuthHeader(action.payload);
    },
    setInitialized: (state) => {
      state.isInitialized = true;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.usercoins = 0;
      state.isInitialized = false;
      // Clear cookies
      Cookies.remove('access_token', cookieConfig);
      Cookies.remove('refresh_token', cookieConfig);
      // Clear headers
      setAuthHeader(null);
    },
    clearError: (state) => {
      state.error = null;
    },
    updateUserData: (state, action) => {
      if (!action.payload) return;
      state.user = {
        ...state.user,
        ...action.payload
      };
      if (typeof action.payload.coins !== 'undefined') {
        state.usercoins = Number(action.payload.coins);
      } else if (typeof action.payload.usercoins !== 'undefined') {
        state.usercoins = Number(action.payload.usercoins);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.access_token;
        state.status = 'succeeded';
        state.error = null;
        state.isInitialized = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Login failed';
        state.isInitialized = false;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.status = 'failed';
        state.token = null;
        state.user = null;
        state.isInitialized = false;
      })
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        if (!action.payload) return;
        
        state.user = action.payload;
        // Safely update coins with proper null checks
        const coins = action.payload?.coins ?? action.payload?.usercoins ?? state.usercoins;
        state.usercoins = Number(coins);
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch user data';
      })
      // Added fetchUserCoins cases
      .addCase(fetchUserCoins.pending, (state) => {
        state.coinsLoading = true;// Optionally set a loading state for coins
        state.status = 'loading';
      })
      .addCase(fetchUserCoins.fulfilled, (state, action) => {
        state.usercoins = Number(action.payload || 0);
        state.coinsLoading = false;
        state.error = null;
      })
      .addCase(fetchUserCoins.rejected, (state, action) => {
        state.coinsLoading = false;// Don't update coins on error, just set error state
        state.error = action.payload || 'Failed to fetch user coins';
      });
  },
});

export const {
  setToken,
  logout,
  clearError,
  updateUserData,
  setInitialized
} = authSlice.actions;

export const selectAuthToken = (state) => state.auth.token;
export const selectIsAuthenticated = (state) => !!state.auth.token;
export const selectIsInitialized = (state) => state.auth.isInitialized;
export const selectUserCoins = (state) => state.auth.usercoins;
export const selectUser = (state) => state.auth.user;
export default authSlice.reducer;
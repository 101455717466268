import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';

const API_URL = `${BASE_URL}/cart/`;

// Helper functions
const getValidDenomination = (item) => {
  const denomination = item.selectedDenomination || item.product?.denominations[0]?.min || 0;
  return Number(denomination);
};

const getValidPrice = (item) => {
  return typeof item.price === 'string' ? parseFloat(item.price) : (typeof item.price === 'number' ? item.price : 0);
};

// Thunks
export const fetchCartItems = createAsyncThunk(
  'cart/fetchItems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/cart/');

      return response.data.map(item => ({
        ...item,
        selectedDenomination: getValidDenomination(item),
        price: getValidPrice(item),
        to_pay: getValidPrice(item) * (item.quantity || 1)
      }));
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch cart items');
    }
  }
);

export const addToCart = createAsyncThunk(
  'cart/addItem',
  async (product, { rejectWithValue }) => {
    try {
      const denomination = getValidDenomination(product);
      const quantity = product.quantity || 1;
      const to_pay = denomination * quantity;

      const response = await axiosInstance.post(
        '/cart/',
        {
          cart_items: [{ 
            product_id: product.id, 
            quantity,
            to_pay,
            selectedDenomination: denomination
          }]
        }
      );

      const price = getValidPrice(response.data);
      return {
        ...response.data,
        selectedDenomination: denomination,
        to_pay,
        quantity,
        price,
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to add item to cart');
    }
});

export const updateCartItemQuantity = createAsyncThunk(
  'cart/updateQuantity',
  async ({ id, quantity, selectedDenomination }, { rejectWithValue }) => {
    try {
      const validDenomination = Number(selectedDenomination);
      const validQuantity = Math.max(1, Number(quantity));
      const calculatedToPay = validDenomination * validQuantity;

      const response = await axiosInstance.patch(
        API_URL, 
        {
          id,
          quantity: validQuantity,
          to_pay: calculatedToPay,
          selectedDenomination: validDenomination
        }
      );

      const price = getValidPrice(response.data);
      return {
        ...response.data,
        id,
        quantity: validQuantity,
        to_pay: calculatedToPay,
        selectedDenomination: validDenomination,
        price
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || "An unexpected error occurred");
    }
  }
);

export const removeCartItem = createAsyncThunk(
  'cart/removeCartItem',
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(API_URL, {
        data: { id }
      });
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while removing item from cart');
    }
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    lastUpdated: null,
  },
  reducers: {
    updateCartItemQuantityLocally: (state, action) => {
      const { id, quantity, selectedDenomination } = action.payload;
      const itemIndex = state.items.findIndex(item => item.id === id);
      if (itemIndex !== -1) {
        const validDenomination = Number(selectedDenomination);
        const validQuantity = Math.max(1, Number(quantity));
        const calculatedToPay = validDenomination * validQuantity;

        state.items[itemIndex] = {
          ...state.items[itemIndex],
          quantity: validQuantity,
          to_pay: calculatedToPay,
          selectedDenomination: validDenomination,
        };
      }
      state.lastUpdated = new Date().toISOString();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.map(item => ({
          ...item,
          selectedDenomination: getValidDenomination(item),
          to_pay: getValidPrice(item) * (item.quantity || 1)
        }));
        state.error = null;
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        showErrorNotification(action.payload?.detail || 'Failed to Cart Items.');
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.items.push({
          ...action.payload,
          selectedDenomination: getValidDenomination(action.payload),
          to_pay: getValidPrice(action.payload) * (action.payload.quantity || 1)
        });
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(updateCartItemQuantity.fulfilled, (state, action) => {
        const index = state.items.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = {
            ...state.items[index],
            ...action.payload,
            selectedDenomination: Number(action.payload.selectedDenomination),
            to_pay: getValidPrice(action.payload) * action.payload.quantity
          };
        }
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(removeCartItem.fulfilled, (state, action) => {
        state.items = state.items.filter(item => item.id !== action.payload);
        state.lastUpdated = new Date().toISOString();
      });
  },
});

export const { updateCartItemQuantityLocally } = cartSlice.actions;
export default cartSlice.reducer;
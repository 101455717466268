import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCategories } from './redux/categorySlice';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { setupAxiosInterceptors } from './redux/authSlice';
import ErrorBoundary from './components/ErrorBoundary';

import LandingPage from "./components/LandingPage";
import Login from "./components/Login";
import Signup from "./components/Signup";
import OTP from "./components/OTPVerification";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Terms from "./components/Terms";
import Contact from "./components/Contact";
import Profile from "./components/Profile";
import EditProfile from "./components/EditProfile";
import Redeem from "./components/RedeemVoucher";
import OrderHistory from "./components/OrderHistory";
import ShoppingCart from "./components/ShoppingCart";
import Cart from "./components/CartItems.js";
import HelpCenter from "./components/HelpCenter";
import RoutePayment from "./components/RoutePayment.js"
import PurchaseVoucher from "./components/vouchers/PurchaseVoucher";
import VerifyDetails from "./components/vouchers/VerifyDetails";
import Welcome from "./components/vouchers/Welcome.js";
import LoadingSpinner from "./components/LoadingSpinner"

import "./App.css";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const router = createBrowserRouter([
    { path: "/", element: <LandingPage /> },
    { path: "/login", element: <Login /> },
    { path: "/signup", element: <Signup /> },
    { path: "/verify-otp", element: <OTP /> },
    { path: "/forgot", element: <ForgotPassword /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "/terms-conditions", element: <Terms /> },
    { path: "/contact-us", element: <Contact /> },
    { path: "/profile", element: <Profile /> },
    { path: "/edit-profile", element: <EditProfile /> },
    { path: "/redeem-voucher", element: <Redeem /> },
    { path: "/order-history", element: <OrderHistory /> },
    { path: "/shopping-cart", element: <ShoppingCart /> },
    { path: "/help-center", element: <HelpCenter /> },
    { path: "/cart", element: <Cart /> },
    { path: "/purchase-voucher", element: <PurchaseVoucher /> },
    { path: "/verify-details", element: <VerifyDetails /> },
    { path: "/welcome", element: <Welcome /> },
    { path: "/payment", element: <RoutePayment /> },
    { path: "/loading", element: <LoadingSpinner /> },
  ]);

  return (
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
}

export default App;
import React, { useEffect,useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import Layout from "./common/Layout";

import { Clock, FileText, ChevronDown, ChevronUp,Coins, Filter, Search } from 'lucide-react'
import LoadingSpinner from './LoadingSpinner';
import { 
  fetchOrderHistory, 
  fetchCoinsHistory,
  selectOrderHistory, 
  selectOrderHistoryStatus,
  selectCoinsHistory,
  selectCoinsHistoryStatus 
} from '../redux/orderHistorySlice';


const CoinHistorySection = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const coinsHistory = useSelector(selectCoinsHistory);
  const coinsHistoryStatus = useSelector(selectCoinsHistoryStatus);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [sortBy, setSortBy] = useState("date");

  useEffect(() => {
    if (isOpen && coinsHistoryStatus === 'idle') {
      dispatch(fetchCoinsHistory());
    }
  }, [isOpen, coinsHistoryStatus, dispatch]);

  const filteredHistory = coinsHistory.filter(item => {
    const matchesSearch = item.coupon_code.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus === "all" ? true : 
      filterStatus === "redeemed" ? item.is_redeemed : !item.is_redeemed;
    return matchesSearch && matchesStatus;
  }).sort((a, b) => {
    if (sortBy === "date") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (sortBy === "amount") {
      return parseFloat(b.amount) - parseFloat(a.amount);
    }
    return 0;
  });

  if (coinsHistoryStatus === 'loading') {
    return <LoadingSpinner />;
  }

  return (
    <div className={`transition-all duration-300 ease-in-out ${isOpen ? 'mb-8' : 'mb-0 h-0 overflow-hidden'}`}>
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900 flex items-center">
            <Coins className="h-6 w-6 text-yellow-500 mr-2" />
            Coin History
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <ChevronUp className="h-6 w-6" />
          </button>
        </div>

        {/* Filters and Search */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search coupon code..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
          </div>

          <select
            className="border border-gray-300 rounded-md px-4 py-2 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="redeemed">Redeemed</option>
            <option value="pending">Pending</option>
          </select>

          <select
            className="border border-gray-300 rounded-md px-4 py-2 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="date">Sort by Date</option>
            <option value="amount">Sort by Amount</option>
          </select>
        </div>

        {/* Coin History Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Coupon Code
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredHistory.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {item.coupon_code}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {parseFloat(item.amount).toFixed(2)} Pts
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      item.is_redeemed 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {item.is_redeemed ? 'Redeemed' : 'Pending'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.user.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(item.created_at).toLocaleDateString()} {new Date(item.created_at).toLocaleTimeString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default function OrderHistory() {
  const dispatch = useDispatch();
  const orderHistory = useSelector(selectOrderHistory);
  const orderHistoryStatus = useSelector(selectOrderHistoryStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [isCoinHistoryOpen, setIsCoinHistoryOpen] = useState(false);
  const [expandedOrders, setExpandedOrders] = React.useState(() => {
    const initialExpanded = new Set();
    // We'll set this after we get the data
    return initialExpanded;
  });
  useEffect(() => {
    if (orderHistoryStatus === 'idle') {
      dispatch(fetchOrderHistory());
    }
  }, [dispatch, orderHistoryStatus]);

  // Group products by status
  const groupedByOrderId = orderHistory.reduce((acc, order) => {
    if (!acc[order.id]) {
      acc[order.id] = [];
    }
    acc[order.id].push(order);
    return acc;
  }, {});
  useEffect(() => {
    if (Object.keys(groupedByOrderId).length > 0) {
      const initialExpanded = new Set(
        Object.keys(groupedByOrderId).slice(0, 3) // Get first 3 order IDs
      );
      setExpandedOrders(initialExpanded);
    }
  }, [orderHistory]); // Depend on orderHistory changes

  const toggleOrderExpansion = (orderId, index) => {
    // Don't allow toggling for first 3 orders
    if (index < 3) return;
    
    setExpandedOrders(prev => {
      const newSet = new Set(prev);
      if (newSet.has(orderId)) {
        newSet.delete(orderId);
      } else {
        newSet.add(orderId);
      }
      return newSet;
    });
  };

 
  if (orderHistoryStatus === 'loading') {
    return (
      <Layout>
        <LoadingSpinner />
      </Layout>
    );
  }
  
  return (
  
  
  <Layout>
  <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
    {/* Header Section */}
    <div className="max-w-7xl mx-auto">
      <div className="bg-white rounded-lg shadow-sm mb-6">
        <div className="p-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Order History</h1>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
              <button
                onClick={() => setIsCoinHistoryOpen(!isCoinHistoryOpen)}
                className="inline-flex items-center px-4 py-2 border-2 border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition-colors"
              >
                <Coins className="h-5 w-5 mr-2" />
                {isCoinHistoryOpen ? 'Hide Coin History' : 'Show Coin History'}
              </button>
              <div className="px-4 py-2 bg-gray-100 rounded-md">
                <span className="text-sm font-medium text-gray-700">
                  Total Orders: {Object.keys(groupedByOrderId).length}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Coin History Section */}
      <CoinHistorySection 
        isOpen={isCoinHistoryOpen}
        onClose={() => setIsCoinHistoryOpen(false)}
      />

      {/* Orders List */}
      <div className="space-y-6">
        {Object.entries(groupedByOrderId).map(([orderId, orders]) => {
          const isExpanded = expandedOrders.has(orderId) || (expandedOrders.size < 3 && orders[0].status === 'Completed');
          const orderDate = new Date(orders[0].order_date).toLocaleDateString();
          const status = orders[0].status;
          const isFirstThree = expandedOrders.size < 3;

          return (
            <div key={orderId} className="bg-white rounded-lg shadow-sm overflow-hidden transition-all duration-200 ease-in-out border-2">
              {/* Order Header */}
              <div 
                className={`p-4 border-l-4 flex items-center justify-between ${
                  status === 'Completed' ? 'border-green-500 bg-green-50' :
                  status === 'Pending' ? 'border-yellow-500 bg-yellow-50' :
                  'border-gray-500 bg-gray-50'
                } ${!isFirstThree ? 'cursor-pointer hover:bg-gray-100' : ''}`}
                onClick={() => !isFirstThree && toggleOrderExpansion(orderId)}
              >
                <div className="flex items-center gap-3">
                  <span className="text-sm font-medium text-gray-500">Order ID:</span>
                  <span className="text-sm font-semibold text-gray-900">{orderId}</span>
                </div>
                <div className="flex items-center gap-3">
                  <Clock className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-500">{orderDate}</span>
                  <div className={`px-3 py-1 rounded-full text-xs font-medium ${
                    status === 'Completed' ? 'bg-green-100 text-green-800' :
                    status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {status}
                  </div>
                  <button
                    className={`inline-flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 text-gray-500 hover:bg-gray-200 transition-colors ${
                      isFirstThree ? ' opacity-50' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent parent click event
                      toggleOrderExpansion(orderId);
                    }}
                  >
                    {isExpanded ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
                  </button>
                </div>
              </div>

              {/* Order Details */}
              {isExpanded && (
                <div className="border-t border-gray-200 grid grid-cols-1 lg:grid-cols-12 gap-6 p-6">
                  <div className="col-span-1 lg:col-span-8 space-y-4">
                    {orders.map((order, index) => (
                      order.items.map((item, itemIndex) => (
                        <div key={`${index}-${itemIndex}`} 
                          className=" rounded-lg p-4 flex items-center gap-6 ">
                          {/* Product Image */}
                          <div className="w-24 h-24 rounded-lg flex items-center justify-center">
  <img 
    src={item.product.image_url || '/placeholder-image.png'} 
    alt={item.product.name}
    className="max-w-full max-h-full object-contain transform hover:scale-105 transition-transform duration-200" 
  />
</div>

                          {/* Product Details */}
                          <div className="flex-1">
                            <h3 className="text-base font-medium text-gray-900 mb-1">
                              {item.product.name}
                            </h3>
                            <p className="text-sm text-gray-600">
                              {order.voucher_type || 'Digital Voucher'}
                            </p>
                            <div className="mt-2 flex items-center gap-2">
                              <span className="text-base font-semibold text-green-600">
                                {item.price_mrp} Pts
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ))}
                  </div>

                  {/* Voucher Details Card */}
                  <div className="col-span-1 lg:col-span-4 bg-gray-50 rounded-lg p-4 border">
                    <h4 className="text-base font-semibold text-gray-700 mb-3 flex items-center">
                      <FileText className="h-4 w-4 mr-2 text-gray-500" />
                      Voucher Details
                    </h4>
                    <div className="grid grid-cols-2 gap-4 text-sm">
                      <div>
                        <span className="text-gray-500">Card Number</span>
                        <p className="font-medium text-gray-900">
                          {orders[0].vouchers?.[0]?.card_number}
                        </p>
                      </div>
                      <div>
                        <span className="text-gray-500">PIN</span>
                        <p className="font-medium text-gray-900">
                          {orders[0].vouchers?.[0]?.pin_code}
                        </p>
                      </div>
                      <div className="col-span-2">
                        <span className="text-gray-500">Claim URL</span>
                        <a 
                          href={orders[0].vouchers?.[0]?.claim_url || "#"}
                          className="block mt-1 text-blue-600 hover:underline truncate"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {orders[0].vouchers?.[0]?.claim_url || "https://example.com/claim"}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  </div>
</Layout>
  
  )
}
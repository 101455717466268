import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from './categorySlice';
import productsReducer from './productSlice';
import signupReducer from './signupSlice';
import authReducer from './authSlice';
import cartReducer from './cartSlice';
import profileReducer from './profileSlice';
import {thunk} from 'redux-thunk';
import placeOrderReducer, { placeOrder } from './placeorderSlice';
import loadCoinReducer from './loadcoinSlice';
import orderHistoryReducer from './orderHistorySlice';
import orderStatusReducer from "./orderstatusSlice";

export const store = configureStore({
  reducer: {
    categories: categoryReducer,
    products : productsReducer ,
    auth: authReducer,
    signup:signupReducer,
    cart:cartReducer,
    profile: profileReducer,
    order:placeOrderReducer,
    loadCoins: loadCoinReducer,
    orderHistory: orderHistoryReducer,
    orderStatus: orderStatusReducer,
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore specific actions to prevent unnecessary API calls
        ignoredActions: [
          'auth/fetchUserData/pending',
          'cart/fetchCartItems/pending',
        ],
      },
    }),
});


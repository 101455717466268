import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';

// Async thunk to fetch order history from the API
export const fetchOrderHistory = createAsyncThunk(
  'orderHistory/fetchOrderHistory',
  async (_, { rejectWithValue }) => {
    const token = Cookies.get('access_token'); // Replace with your method of getting the token
    try {
      const response = await axios.get(`${BASE_URL}/order-history/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Return the fetched data as the action payload
    } catch (error) {
      console.error('Error fetching order history:', error);
      return rejectWithValue(error.response?.data || 'Failed to fetch order history');
    }
  }
);
export const fetchCoinsHistory = createAsyncThunk(
  'orderHistory/fetchCoinsHistory',
  async (_, { rejectWithValue }) => {
    const token = Cookies.get('access_token');
    try {
      const response = await axios.get(`${BASE_URL}/coins-history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.coins; // Extract coins array from response
    } catch (error) {
      console.error('Error fetching coins history:', error);
      return rejectWithValue(error.response?.data || 'Failed to fetch coins history');
    }
  }
);

// Create the slice for order history
const orderHistorySlice = createSlice({
  name: 'orderHistory',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    coinsHistory: [],
    coinsHistoryStatus: 'idle',
    coinsHistoryError: null,
  },
  reducers: {
    clearOrderHistoryState: (state) => {
      state.items = [];
      state.status = 'idle';
      state.error = null;
      state.coinsHistory = [];
      state.coinsHistoryStatus = 'idle';
      state.coinsHistoryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Order history cases
      .addCase(fetchOrderHistory.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchOrderHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchOrderHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch order history';
        showErrorNotification(action.payload?.detail || 'Failed to fetch Order History.');
      })
      // Coins history cases
      .addCase(fetchCoinsHistory.pending, (state) => {
        state.coinsHistoryStatus = 'loading';
        state.coinsHistoryError = null;
      })
      .addCase(fetchCoinsHistory.fulfilled, (state, action) => {
        state.coinsHistoryStatus = 'succeeded';
        state.coinsHistory = action.payload;
      })
      .addCase(fetchCoinsHistory.rejected, (state, action) => {
        state.coinsHistoryStatus = 'failed';
        state.coinsHistoryError = action.payload || 'Failed to fetch coins history';
        showErrorNotification(action.payload?.detail || 'Failed to fetch profile data.');
      });
  },
});

// Export actions and selectors
export const { clearOrderHistoryState } = orderHistorySlice.actions;
export const selectOrderHistory = (state) => state.orderHistory.items;
export const selectOrderHistoryStatus = (state) => state.orderHistory.status;
export const selectOrderHistoryError = (state) => state.orderHistory.error;
export const selectCoinsHistory = (state) => state.orderHistory.coinsHistory;
export const selectCoinsHistoryStatus = (state) => state.orderHistory.coinsHistoryStatus;
export const selectCoinsHistoryError = (state) => state.orderHistory.coinsHistoryError;

export default orderHistorySlice.reducer;



import React from "react";
import logo2 from "../assets/logo2.png";
import backgroundImage from "../assets/login-bg.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function ResetPassword() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };
  return (
    <div className="min-h-screen flex relative">
      <div className="bg-[#1B2D3E] w-[40%]"></div>
      <div className="grow">
        <img
          src={backgroundImage}
          className="max-h-screen object-cover h-full w-full"
        />
      </div>
      <div className="absolute login-overlay h-full w-full px-20 py-10">
        <div className="flex justify-between mb-5">
          <img src={logo2} className="h-8" />
          <a href="/signup">
            <button className="bg-[#5BD318] text-lg px-10 py-2 rounded text-white font-semibold">
              SIGN UP
            </button>
          </a>
        </div>
        <div className=" grid grid-cols-2">
          <div className="grid place-items-center">
            <div className="w-[60%] ml-[-80px] mt-[120px]">
              <p className="text-2xl mb-3 font-medium text-white">
                Set New Password
              </p>
              <p className="text-md text-gray-300">
                No worries, we’ll send you reset instruction
              </p>
              <div className="flex flex-col gap-5 mt-4">
                <div>
                  <label className="block text-white">Password</label>
                  <input
                    placeholder="New Password"
                    type="password"
                    className="w-full h-12 rounded pl-3 mt-2 bg-[#294157] border border-gray-600"
                  />
                  <p className="text-sm text-gray-500 mt-1">
                    Password should be 8 chars long
                  </p>
                </div>
                <div>
                  <label className="block text-white">Confirm Password</label>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className="w-full h-12 rounded pl-3 mt-2 bg-[#294157] border border-gray-600"
                  />
                  <p className="text-sm text-gray-500 mt-1">
                    Both passwords should match
                  </p>
                </div>
              </div>

              <a href="/login">
                <button className="mt-[60px] px-10 py-3 bg-[#5BD318] text-white font-semibold rounded w-full">
                  Reset Password
                </button>
              </a>
            </div>
          </div>
          <div className="relative">
            <div className="slider-container mt-[65%]">
              <Slider {...settings}>
                <div className="text-center">
                  <h3 className="italic text-2xl text-white">Get Your</h3>
                  <p className="text-3xl font-semibold text-white w-[30%] mx-auto">
                    Voucher Redeem with us Easily
                  </p>
                </div>
                <div className="text-center">
                  <h3 className="italic text-2xl text-white">Get Your</h3>
                  <p className="text-3xl font-semibold text-white w-[30%] mx-auto">
                    Voucher Redeem with us Easily
                  </p>
                </div>
                <div className="text-center">
                  <h3 className="italic text-2xl text-white">Get Your</h3>
                  <p className="text-3xl font-semibold text-white w-[30%] mx-auto">
                    Voucher Redeem with us Easily
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";

import profile from "../../assets/profile.png";
import { IoCartOutline, IoSearchOutline, IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../redux/categorySlice';

import coinsicon from "../../assets/coins.png";
import { fetchProducts } from "../../redux/productSlice";
import { logout, fetchUserData, selectIsAuthenticated } from '../../redux/authSlice';
import RedemptionModal from '../RedemptionModal';
import { clearProducts } from '../../redux/productSlice';
import { fetchCartItems } from '../../redux/cartSlice';



export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // Redux selectors
  const { user, status: authStatus } = useSelector((state) => state.auth);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const categories = useSelector((state) => state.categories.categories);
  const categoriesStatus = useSelector((state) => state.categories.status);
  const { items: cartItems, status: cartStatus } = useSelector((state) => state.cart);

  // Local state
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [showRedemptionModal, setShowRedemptionModal] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [priceMin, setPriceMin] = useState();
  const [priceMax, setPriceMax] = useState();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  // Initial data fetch
  useEffect(() => {
    const initializeData = async () => {
      if (categoriesStatus === 'idle') {
        await dispatch(fetchCategories());
      }
      if (isAuthenticated && authStatus !== 'loading') {
        await Promise.all([
          dispatch(fetchUserData()),
          dispatch(fetchCartItems())
        ]);
      }
      setIsInitialLoad(false);
    };

    initializeData();
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (categories?.length > 0) {
      setVisibleCategories(showAllCategories ? categories : categories.slice(0, 5));
    }
  }, [showAllCategories, categories]);

  const handleCategorySelect = (category) => {
    if (category?.id) {
      setSelectedCategory(category);
      dispatch(clearProducts());
      dispatch(fetchProducts({
        categoryId: category.id,
        subcategoryIds: selectedSubCategories,
        page: 1,
        priceMin,
        priceMax,
        perPage: 8,
      }));
      navigate("/", {
        state: {
          categoryId: category.id,
          selectedSubCategories,
        },
      });
      setIsMobileMenuOpen(false);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleCartClick = (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate('/cart');
    }
  };

  const cartCount = isAuthenticated ? (cartItems?.length || 0) : 0;

  if (isInitialLoad) {
    return <div>Loading...</div>;
  }

  return (
    <div className="sticky top-0 z-50 bg-[#162533] text-white">
      {/* Top bar */}
      <div className="bg-[#162533]  border-gray-700">
        <div className="container mx-auto px-4 py-2 flex justify-end space-x-6 text-sm text-gray-300">
          <div className="hidden lg:flex items-center gap-6">
            <a href="/help-center" className="hover:text-white transition-colors duration-200">Help Center</a>
            <a href="/order-history" className="hover:text-white transition-colors duration-200">Track Order</a>
            <button 
              onClick={() => setShowRedemptionModal(true)} 
              className="hover:text-white transition-colors duration-200"
            >
              How to Redeem?
            </button>
            {isAuthenticated && (
              <button 
                onClick={handleLogout} 
                className="text-red-400 hover:text-red-300 transition-colors duration-200"
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Main header section */}
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row lg:items-center py-2 lg:py-0 lg:h-16">
          {/* Top row for mobile */}
          <div className="flex items-center justify-between w-full lg:w-auto h-12 lg:h-auto">
            {/* Left section */}
            <div className="flex items-center gap-4">
              <button 
                 className="lg:hidden flex items-center justify-between px-4 py-3"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                {isMobileMenuOpen ? <IoCloseOutline size={24} /> : <IoMenuOutline size={24} />}
              </button>
              
              {/* Logo */}
              <a href="/" className="flex-shrink-0">
  <img 
    src={logo} 
    alt="Logo" 
    className="h-7 md:h-8 lg:h-12 object-contain hidden lg:block mb-8"
     // Desktop logo
  />
  {/* <img 
    // src={mobilelogo} // Assuming you have a separate mobile logo asset
    // alt="Logo" 
    // className="h-7 lg:hidden object-contain" // Mobile logo
  /> */}
</a>
            </div>

            {/* Search toggle and cart for mobile */}
            <div className="flex items-center gap-3 lg:hidden">
              <button 
                className="p-2 hover:bg-[#1E2A38] rounded-lg transition-colors duration-200"
                onClick={() => setShowSearch(!showSearch)}
              >
                <IoSearchOutline size={24} />
              </button>
              
              <button 
                onClick={handleCartClick} 
                className="relative p-2 hover:bg-[#1E2A38] rounded-lg transition-colors duration-200"
              >
                <IoCartOutline size={22} />
                {cartCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                    {cartCount}
                  </span>
                )}
              </button>
            </div>
          </div>

          {/* Search bar - Both mobile and desktop */}
          <div className={`${showSearch ? 'block' : 'hidden'} lg:block lg:flex-grow lg:mx-8 mt-4 lg:mt-0`}>
          <div className="relative flex-1 mx-4">
          <IoSearchOutline className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search for coupons..."
            className="w-full bg-[#1E2A38] text-white rounded-lg pl-10 pr-4 py-2 text-sm"
          />
        </div>
          </div>

          {/* Right section - Points, Cart, User Profile */}
          <div className="hidden lg:flex items-center gap-4">
      {/* Points display - Hidden on mobile */}
      {isAuthenticated && user && (
        <div 
          className="hidden lg:flex flex-col items-center justify-center relative rounded-lg border border-yellow-400 py-1 px-3 bg-[#1E2A38]"
          style={{
            boxShadow: "inset rgba(255, 204, 0, 0.5) 1px 4px 5px 1px",
          }}
        >
          <p className="text-xl text-yellow-400 font-bold leading-none">{user.coins || 0}</p>
          <div className="flex items-center gap-1">
            <img src={coinsicon} alt="Points" className="h-4 w-4" />
            <p className="text-sm text-yellow-400">Points</p>
          </div>
        </div>
      )}

            {/* Desktop Cart */}
            <div className="hidden lg:flex items-center">
              <button 
                onClick={handleCartClick} 
                className="flex items-center gap-2 p-2 hover:bg-[#1E2A38] rounded-lg transition-colors duration-200"
              >
                <div className="relative">
                  <IoCartOutline size={24} />
                  {cartCount > 0 && (
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                      {cartCount}
                    </span>
                  )}
                </div>
                <span className="text-sm font-medium">CART</span>
              </button>
            </div>

            {/* Desktop User Profile */}
            {isAuthenticated && user ? (
              <div className="hidden lg:flex items-center gap-3 pl-4 border-l border-gray-700">
                <div className="text-right">
                  <p className="text-sm font-medium truncate">{user.username}</p>
                  <p className="text-xs text-gray-400 truncate">{user.email}</p>
                </div>
                <img 
                  src={profile} 
                  alt="Profile" 
                  className="w-8 h-8 rounded-full object-cover border-2 border-gray-600"
                />
              </div>
            ) : (
              <a 
                href="/login" 
                className="bg-[#21374A] text-[#5BD318] px-4 py-2 rounded-lg text-sm font-medium hover:bg-[#2a4760] transition-colors duration-200"
              >
                SIGN IN
              </a>
            )}
          </div>
        </div>
      </div>

      {/* Categories navigation */}
      <div className="bg-white border-b border-gray-200">
        <div className="container mx-auto px-4">
          <div className="h-12 flex items-center justify-between">
            <div className="hidden lg:flex items-center space-x-8">
              {visibleCategories.map((category, index) => (
                <button
                  key={index}
                  onClick={() => handleCategorySelect(category)}
                  className="text-gray-700 hover:text-gray-900 font-medium transition-colors duration-200"
                >
                  {category.name}
                </button>
              ))}
              {categories?.length > 5 && (
                <button 
                  onClick={() => setShowAllCategories(!showAllCategories)}
                  className="text-blue-500 hover:text-blue-600 font-medium transition-colors duration-200"
                >
                  {showAllCategories ? 'Show Less' : 'Show More'}
                </button>
              )}
            </div>
            <a 
              href="/redeem-voucher" 
              className="ml-auto bg-[#5BD318] text-white px-6 py-2 rounded-lg font-medium hover:bg-green-600 transition-colors duration-200"
            >
              Redeem Points
            </a>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed inset-0 bg-[#162533] z-50 overflow-y-auto">
          {/* Mobile menu header */}
          <div className="px-4 py-4 border-b border-gray-700">
            {isAuthenticated && user && (
              <div className="flex items-center gap-3">
                <img 
                  src={profile} 
                  alt="Profile" 
                  className="w-10 h-10 rounded-full object-cover border-2 border-gray-600"
                />
                <div>
                  <p className="text-base font-medium">{user.username}</p>
                  <p className="text-sm text-gray-400">{user.email}</p>
                </div>
              </div>
            )}
          </div>

          {/* Mobile menu categories */}
          <div className="px-4 py-4 space-y-1">
            {categories?.map((category) => (
              <button
                key={category.id}
                onClick={() => handleCategorySelect(category)}
                className="block w-full text-left text-white py-3 px-4 hover:bg-[#1E2A38] rounded-lg transition-colors duration-200"
              >
                {category.name}
              </button>
            ))}
          </div>
          
          {/* Mobile menu links */}
          <div className="px-4 py-4 border-t border-gray-700 space-y-1">
            <a href="/help-center" className="block text-gray-300 py-3 px-4 hover:bg-[#1E2A38] rounded-lg">
              Help Center
            </a>
            <a href="/order-history" className="block text-gray-300 py-3 px-4 hover:bg-[#1E2A38] rounded-lg">
              Track Order
            </a>
            <button 
              onClick={() => setShowRedemptionModal(true)} 
              className="block w-full text-left text-gray-300 py-3 px-4 hover:bg-[#1E2A38] rounded-lg"
            >
              How to Redeem?
            </button>
            <a href="/redeem-voucher" className="block text-green-400 py-3 px-4 hover:bg-[#1E2A38] rounded-lg font-medium">
              Redeem Points
            </a>
            {isAuthenticated && (
              <button 
                onClick={handleLogout}
                className="block w-full text-left text-red-400 py-3 px-4 hover:bg-[#1E2A38] rounded-lg"
              >
                Logout
              </button>
            )}
          </div>
        </div>
      )}

      <RedemptionModal 
        isOpen={showRedemptionModal} 
        onClose={() => setShowRedemptionModal(false)} 
      />
    </div>
  );
}
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../src/config';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
      'Content-Type': 'application/json',
       'Accept': 'application/json'
  }
});

export default axiosInstance ;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios'; // Import the configured axios instance
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';
// Fetch profile
export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile', 
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/edit-profile/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { detail: error.message });
    }
  }
);

// Update profile
export const updateProfile = createAsyncThunk(
  'profile/updateProfile', 
  async (profileData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${BASE_URL}/edit-profile/`,
        profileData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { detail: error.message });
    }
  }
);

// Redux slice for profile management
const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: {}, // Initialize with an empty object to avoid destructure errors
    status: 'idle', // Can be 'idle', 'loading', 'succeeded', or 'failed'
    error: null, // Holds error information
  },
  reducers: {
    clearProfileError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetching profile
      .addCase(fetchProfile.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Handle updating profile
      .addCase(updateProfile.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        showErrorNotification(action.payload?.detail || 'Failed to fetch profile data.');
      });
  },
});

export const { clearProfileError } = profileSlice.actions;
export default profileSlice.reducer;

// Selectors
export const selectProfile = (state) => state.profile.data;
export const selectProfileStatus = (state) => state.profile.status;
export const selectProfileError = (state) => state.profile.error;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';


// Async thunk for fetching products
export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ categoryId, subcategoryIds, page = 1, perPage = 8,priceMin, priceMax,append = false }, { getState }) => {
   
    const response = await axios.get(`${BASE_URL}/products/`, {
      params: {
        category: categoryId ? categoryId : undefined,
        subcategories: subcategoryIds.length > 0 ? subcategoryIds : undefined,
        page,
        perPage,
        price_min: priceMin, // Pass priceMin as query parameter
        price_max: priceMax,
        
      }
    });
  
    return { products: response.data.results, append, hasMore: response.data.next !== null,totalCount: response.data.count  };
  }
);

// Product slice
const initialState = {
  products: [],
  status: 'idle',
  error: null,
  hasMore: true,
  page: 1,
  SubCategories: [],
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSubCategories(state, action) {
      state.SubCategories = action.payload;
    },
    clearProducts(state) {
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        const products = action.payload.products || [];
        if (action.payload.append) {
          state.products = [...state.products, ...products];  // Append products only if append mode is true
        } else {
          state.products = products;  // Replace products when not appending
        }
        state.hasMore = action.payload.hasMore; // Set hasMore from the response
        state.status = 'succeeded';
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message||"contact the adminstration ";
      

      });
  },
});

export const { setSubCategories, clearProducts } = productSlice.actions;
export const selectAllProducts = (state) => state.products.products;
export const selectProductStatus = (state) => state.products.status;
export const selectProductError = (state) => state.products.error;
export const selectedSubCategories = (state) => state.products.SubCategories;
export const selectHasMoreProducts = (state) => state.products.hasMore;

export default productSlice.reducer;

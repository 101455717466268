import React, { useState } from 'react';

const RedemptionModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('coins');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black/30 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative bg-white rounded-lg w-full max-w-2xl mx-4 shadow-xl">
        {/* Modal Header */}
        <div className="p-6 pb-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-gray-900">Redemption Guide</h3>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 text-2xl"
            >
              ×
            </button>
          </div>
          <p className="text-gray-600 text-sm mt-2">
            To redeem on rewards.io stores follow the below guides.
          </p>
        </div>

        {/* Tabs */}
        <div className="px-6 border-b">
          <div className="flex gap-6">
            <button
              className={`py-2 px-1 -mb-px font-medium text-sm ${
                activeTab === 'coins'
                  ? 'text-[#5BD318] border-b-2 border-[#5BD318]'
                  : 'text-gray-500'
              }`}
              onClick={() => setActiveTab('coins')}
            >
              Rewards.io Coins Redemption
            </button>
            <button
              className={`py-2 px-1 -mb-px font-medium text-sm ${
                activeTab === 'codes'
                  ? 'text-[#5BD318] border-b-2 border-[#5BD318]'
                  : 'text-gray-500'
              }`}
              onClick={() => setActiveTab('codes')}
            >
              Reward Codes Redemption
            </button>
          </div>
        </div>

        {/* Modal Content */}
        <div className="p-6">
          {activeTab === 'coins' && (
            <div className="space-y-6">
              <div className="flex gap-4">
                <div className="w-48 h-32 bg-gray-100 rounded-lg"></div>
                <div>
                  <h4 className="font-semibold mb-2">Step 1</h4>
                  <p className="text-sm text-gray-600">
                    Add products to cart and proceed to checkout.
                  </p>
                </div>
              </div>

              <div className="flex gap-6">
        <div className="bg-gray-100 flex-shrink-0 h-32 rounded-lg w-48"></div>
        <div>
          <h4 className="font-semibold mb-2">Step 2</h4>
          <p className="text-gray-600">
            At checkout, select your points balance and redeem against the shopping cart value. You can pay additional amount via other payment modes as well.
          </p>
        </div>
      </div>
    </div>
          )}

          {/* Contact Information */}
          <div className="mt-8 pt-6 border-t border-gray-100">
            <p className="text-sm text-gray-500">
              If you have any queries, kindly contact us via Email or Whatsapp
            </p>
            <div className="flex gap-6 mt-2 text-sm">
              <div className="flex items-center gap-2">
                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <span className="text-gray-600">support@rewards.io</span>
              </div>
              <div className="flex items-center gap-2">
      <svg 
        className="w-4 h-4" 
        viewBox="0 0 24 24" 
        fill="#25D366"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          fillRule="evenodd" 
          clipRule="evenodd" 
          d="M20.463 3.488C18.217 1.24 15.231 0 12.05 0 5.495 0 0.16 5.334 0.16 11.89c0 2.096 0.547 4.142 1.588 5.945L0.0599999 24l6.304-1.654c1.737 0.947 3.693 1.446 5.683 1.446h0.005c6.554 0 11.89-5.335 11.89-11.891 0-3.18-1.24-6.165-3.48-8.413ZM12.05 21.785h-0.004c-1.774 0-3.513-0.477-5.031-1.378l-0.361-0.214-3.741 0.981 0.999-3.648-0.235-0.374c-0.99-1.574-1.513-3.393-1.513-5.262 0-5.443 4.432-9.874 9.89-9.874 2.641 0 5.124 1.03 6.989 2.898 1.865 1.869 2.89 4.352 2.888 6.993 0 5.443-4.431 9.878-9.881 9.878Zm5.418-7.397c-0.298-0.149-1.759-0.868-2.031-0.967-0.272-0.099-0.47-0.149-0.669 0.148-0.198 0.298-0.768 0.967-0.941 1.166-0.173 0.198-0.347 0.223-0.644 0.074-0.298-0.149-1.255-0.462-2.39-1.475-0.883-0.788-1.48-1.761-1.653-2.059-0.173-0.298-0.018-0.458 0.13-0.606 0.134-0.133 0.297-0.347 0.446-0.521 0.149-0.174 0.198-0.298 0.297-0.497 0.099-0.198 0.05-0.372-0.025-0.521-0.074-0.149-0.669-1.612-0.916-2.207-0.242-0.579-0.487-0.501-0.669-0.51-0.173-0.008-0.371-0.01-0.57-0.01-0.198 0-0.52 0.074-0.792 0.372-0.272 0.298-1.04 1.017-1.04 2.479 0 1.463 1.065 2.876 1.213 3.074 0.149 0.198 2.096 3.2 5.077 4.487 0.709 0.306 1.262 0.489 1.694 0.625 0.712 0.227 1.36 0.195 1.871 0.118 0.57-0.085 1.758-0.719 2.006-1.413 0.248-0.694 0.248-1.29 0.173-1.413-0.074-0.124-0.272-0.198-0.57-0.347Z"
        />
      </svg>
      <span className="text-gray-600">+91 1234 5678 90</span>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedemptionModal;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';

const initialState = {
  status: 'idle',
  error: null,
  orderId: null,
  orderLink: null,
  usercoins: 0,
  paymentLinks: {
    phonePe: '',
    paytm: '',
    gpay: '',
    other: ''
  },
  amount: null,
  orderData: null,
  orderStatus: null,
  
  orderAmount: null,
  orderCreatedAt: null,
  orderLastUpdated: null,
  cartItemsCleared: false
};

export const placeOrder = createAsyncThunk(
  'order/placeOrder',
  async (orderData, { rejectWithValue }) => {
    try {
      console.log('Sending payload to API:', orderData);
      // const response = await axiosInstance.post(`${BASE_URL}/place-order/`, orderData);
      const response = await axiosInstance.post(`${BASE_URL}/place-order/`, {
        amount: orderData.amount,
        order_number: orderData.order_number,
        redirect_url: orderData.redirect_url,
        notify_url: orderData.notify_url,
        coins_used: orderData.coins_used
      });
      const data = response.data;

      if (data) {
        console.log("Processed Backend Data:", data);
        return {
          orderId: data.orderId,
          orderLink: data.orderLink || data.redirect_url,
          paymentLinks: data.paymentLinks || {},
          updatedCoins: data.updatedCoins
        };
      } else {
        throw new Error("Invalid backend response");
      }
    } catch (error) {
      console.error('Error placing order:', error);
      return rejectWithValue(error.response?.data || 'Failed to place order');
    }
  }
);


export const updateUserCoins = createAsyncThunk({
  name: 'updateUserCoins',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(placeOrder.fulfilled, (state, action) => {
        // Update user coins after successful order
        if (action.payload?.updatedCoins) {
          state.usercoins = action.payload.updatedCoins;
        }
      });
  },
});
export const getOrderStatus = createAsyncThunk(
'order/getOrderStatus',
  async ({order_id,decoded_status}, { rejectWithValue }) => {
    try {
      console.log('Fetching order status:', order_id,decoded_status);
      const response = await axiosInstance.get(`${BASE_URL}/place-order/?order_id=${order_id}&decoded_status=${decoded_status}`);
      
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.error('Error fetching order status:', error);
      return rejectWithValue(error.response?.data || 'Failed to fetch order status');
    }
  }
);

const placeOrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearOrderState: (state) => {
      state.status = 'idle';
      state.error = null;
      state.orderId = null;
      state.orderLink = null;
      state.paymentLinks = {
        phonePe: '',
        paytm: '',
        gpay: '',
        other: '',
      };
      state.amount = null;
      state.orderData = null;
      state.orderStatus = null;
      state.orderCreatedAt = null;
      state.orderLastUpdated = null;
      state.cartItemsCleared = false;
    },
    setOrderAmount: (state, action) => {
      state.amount = action.payload;
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setUserCoins: (state, action) => {
      state.usercoins = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeOrder.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.status = 'succeess';
        state.orderId = action.payload.orderId;
        state.orderLink = action.payload.orderLink;
        state.paymentLinks = action.payload.paymentLinks;
        state.amount = action.meta.arg.amount;
        state.orderData = action.meta.arg;
        if (action.payload.updatedCoins !== undefined) {
          state.userCoins = action.payload.updatedCoins;
        }
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to place order';
      })
      .addCase(getOrderStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getOrderStatus.fulfilled, (state, action) => {
        console.log("Order status payload:", action.payload);
        state.status = 'succeeded';
        
        state.orderStatus = action.payload.status;
        
        state.orderAmount = action.payload.amount;
        state.orderCreatedAt = action.payload.created_at;
        state.orderLastUpdated = action.payload.last_updated;
        state.cartItemsCleared = action.payload.cart_items_cleared;
      })
      .addCase(getOrderStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch order status';
      });
  }
});

export const { setOrderAmount, setUserCoins, clearOrderState, setOrderData } = placeOrderSlice.actions;

export const selectOrderStatus = (state) => state.order.status;
export const selectOrderError = (state) => state.order.error;
export const selectOrderId = (state) => state.order.orderId;
export const selectOrderLink = (state) => state.order.orderLink;
export const selectPaymentLinks = (state) => state.order.paymentLinks;
export const selectOrderAmount = (state) => state.order.amount;
export const selectOrderData = (state) => state.order.orderData;
export const selectOrderDetails = (state) => ({
  orderStatus: state.order.orderStatus,
  
  orderAmount: state.order.orderAmount,
  orderCreatedAt: state.order.orderCreatedAt,
  orderLastUpdated: state.order.orderLastUpdated,
  cartItemsCleared: state.order.cartItemsCleared
});

export default placeOrderSlice.reducer;
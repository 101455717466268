import React from "react";

import Layout from "./common/Layout";
export default function Contact() {
  return (
    <Layout>
      <div className="bg-[#F5F5F5] p-20">
        <div className="container mx-auto">
          <div className="grid w-[70%] mx-auto grid-cols-2">
            <div>
              <h2 className="text-3xl w-[75%] font-semibold">
                <span className="text-[#5BD318]">
                  Need help with something?
                </span>{" "}
                You’re are at right place, we will help you out.
              </h2>
              <div className="my-6">
                <h4 className="text-xl font-semibold">Phone Number</h4>
                <p className="text-md font-normal text-gray=600">
                  +91 1234 5678 90
                </p>
              </div>
              <div className="my-6">
                <h4 className="text-xl font-semibold">Email</h4>
                <p className="text-md font-normal text-gray=600">
                  support@rewaards.io
                </p>
              </div>
              <div className="my-6">
                <h4 className="text-xl font-semibold">Address</h4>
                <p className="w-3/5 text-md font-normal text-gray=600">
                  No. 259, 1st floor 1st Main, 4th Cross Rd, above A1 Puncture
                  Shop, 3rd Phase, Banashankari 3rd Stage, Bengaluru, Karnataka
                  560085
                </p>
              </div>
            </div>
            <div>
              <div className="form-card shadow-xl p-8 rounded-xl bg-white">
                <h3 className="text-xl font-semibold">Write to Us</h3>
                <p className="text-md text-gray-">
                  Login & Get redirected to stores to redeem points or the admin
                  portal to manage rewards.
                </p>
                <div className="form mt-3">
                  <form>
                    <div className="mb-3">
                      <label className="text-md font-medium">Full Name</label>
                      <input
                        type="text"
                        placeholder="John Doe"
                        className="w-full border bg-[#F7F7F7] brder-gray-300 rounded pl-4 h-14 mt-2"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-md font-medium">Email</label>
                      <input
                        type="text"
                        placeholder="johndoe123@gmail.com"
                        className="w-full border bg-[#F7F7F7] brder-gray-300 rounded pl-4 h-14 mt-2"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-md font-medium">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        placeholder="Mobile"
                        className="w-full border brder-gray-300 bg-[#F7F7F7] rounded pl-4 h-14 mt-2"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-md font-medium">Message</label>
                      <textarea
                        type="text"
                        placeholder="Your Message"
                        className="w-full border bg-[#F7F7F7] border-gray-300 rounded pl-4 pt-2 h-[100px] mt-2"
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full mt-3 rounded py-2 bg-[#5BD318] text-white font-semibold"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

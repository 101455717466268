import React from "react";
import Layout from "./common/Layout";
import help from "../assets/help.png";

export default function HelpCenter() {
  return (
    <Layout>
      <div className="bg-[#F5F5F5] min-h-screen py-8">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-sm">
            <div className="p-8 md:p-10">
              {/* Header Section */}
              <div className="text-center space-y-4 mb-12">
                <h3 className="text-xl md:text-2xl font-semibold text-[#5BD318]">
                  Surprising your loved ones is even more rewarding now!
                </h3>
                <p className="text-sm md:text-base font-medium max-w-2xl mx-auto">
                  Get Rewards.io points on your purchase of E-Gift Cards & Gift Cards across Rewards.io & Rewards.io App{" "}
                  <span className="text-[#5BD318] cursor-pointer">(Download from here)</span>{" "}
                  and use these coins on your next purchases.
                </p>
                <p className="text-sm md:text-base font-medium">
                  Each Rewards.io points is equal to Re.1. Isn't that just Awesome?
                </p>
              </div>
              <div className="relative my-12">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-dashed border-gray-200"></div>
                </div>
              </div>
              {/* How to Earn Section */}
              <div className="py-10 ">
                <h3 className="text-xl md:text-2xl font-bold text-center mb-8">
                  How to earn <span className="text-[#5BD318]">Rewards.io Points</span>
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  {[1, 2, 3].map((item) => (
                    <div key={item} className="text-center">
                      <div className="bg-[#F8F8F8] w-16 h-16 rounded-lg mx-auto mb-4 flex items-center justify-center">
                        <img src={help} alt="help icon" className="w-8 h-8" />
                      </div>
                      <p className="text-sm text-gray-600 max-w-[250px] mx-auto">
                        You can earn Rewards.io Points through various promotions on E-Gift Cards & Gift Cards on Rewards.io & Rewards.io app.
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="relative my-12">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-dashed border-gray-200"></div>
                </div>  
              </div>
              {/* How to Redeem Section */}
              <div className="py-10 text-center">
                <h3 className="text-xl md:text-2xl font-bold mb-6">
                  How to redeem <span className="text-[#5BD318]">Rewards.io Points</span>
                </h3>
                <div className="space-y-4">
                  <p className="text-gray-600">The redemption of Rewards.io Points is easy.</p>
                  <p className="text-gray-600 max-w-2xl mx-auto">
                    Just select the brand E-Gift Card that you want to buy, go to the checkout and select the Rewards.io balance option.
                  </p>
                  <button className="mt-8 bg-[#5BD318] text-white px-8 py-3 rounded-full hover:bg-[#4ca815] transition-colors">
                    Checkout offers and earn Rewards.io Points
                  </button>
                </div>
              </div>
              <div className="relative my-12">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-dashed border-gray-200"></div>
                </div>
              </div>
              {/* Few Things to Note Section */}
              <div className="py-10 ">
                <h3 className="text-xl md:text-2xl font-bold text-gray-600 text-center mb-8">
                  Few things to note
                </h3>
                <ol className="list-decimal pl-6 space-y-4 text-gray-600 text-sm md:text-base">
                  {[
                    "Each Rewards.io Point is equal to Re.1.",
                    "Woohoo Coins can be used to make partial payment while buying a brand gift card. Depending on the brand chosen and the order value, a part of the payable amount can be paid using Woohoo Coins. The balance amount can be paid using any of the other available payment modes.",
                    

"The redemption of Woohoo Coins cannot be clubbed with any other ongoing offer.",
"Woohoo Coins can't be exchanged for cash or gift cards. They can only be used to pay a part of the order value during a gift card purchase on woohoo.in & woohoo app.",
"In case you have Woohoo Coins along with another Woohoo balance, then the redemption of Woohoo Coins will happen first.",
"Woohoo Coins are valid only for 7 days after the date of issue. Unused balance will expire after the validity period.",
"There is no limit of the number of Woohoo Coins a user can have in his/her Woohoo account.",
"Woohoo Coins can be used only on Woohoo.in and Woohoo App (Click here to download). Woohoo Coins are not redeemable on the Woohoo Spot store on Google Pay App.",
"The brands available for redemption against the Woohoo Coins, and the Woohoo Coins in its entirety can be withdrawn from Woohoo.in and Woohoo App on any day, and at any time, without any prior intimation.",
"Terms and Conditions are subject to change without prior notice at the sole discretion of Pine Labs Pvt Ltd.(Woohoo)",
"For any queries contact www.woohoo.in at 080 6980 6393 or write to us on support@woohoo.in.",
                    // ... Add all list items
                  ].map((item, index) => (
                    <li key={index} className="leading-relaxed">{item}</li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import logo from "../../assets/logo.svg";
import axiosInstance from "../../axios";
import payments from "../../assets/payment-img.png";
import PurchaseVoucher from "./PurchaseVoucher";
import VerifyDetails from "./VerifyDetails";
export default function Welcome() {
  return (
    <Box container sx={{ minHeight: "100vh", display:"flex", flexDirection:"column" }}>
      <Box
        item
        sx={{
          background: "#fff",
          py: 2,
          px: 4,
          "@media (max-width:768px)": {
            background: "#EBF8E9"
          },
        }}
      >
        <img
        style={{width:"100px"}}
          src={logo}
        />
      </Box>
      <Box
        item
        sx={{
          background: "#F0F5EF",
          
          position: "relative",
          flexGrow: "1",
          height:"100%",
          "@media (max-width:768px)": {
            background: "#fff"
          },
        }}
      >
        {/* <PurchaseVoucher /> */}
        <VerifyDetails/>
      </Box>
      
    </Box>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';

const initialState = {
  orderDetails: null,
  paymentLinks: {},
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  pollingStatus: "idle", // New state for polling updates
  error: null,
  orderId: null,
};

export const fetchOrderStatus = createAsyncThunk(
  "orderStatus/fetchOrderStatus",
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/order-status/`, {
        params: { 
          order_id: orderId,
          decode_status: false 
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch order status"
      );
    }
  }
);

const orderStatusSlice = createSlice({
  name: "orderStatus",
  initialState,
  reducers: {
    clearOrderState(state) {
      state.orderDetails = null;
      state.paymentLinks = {};
      state.status = "idle";
      state.pollingStatus = "idle";
      state.error = null;
      state.orderId = null;
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderStatus.pending, (state, action) => {
        // Only set loading state on initial load, not during polling
        if (state.status === "idle") {
          state.status = "loading";
        }
        state.pollingStatus = "loading";
        state.error = null;
      })
      .addCase(fetchOrderStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pollingStatus = "succeeded";
        state.orderDetails = action.payload;
        state.paymentLinks = action.payload.paymentLinks || {};
      })
      .addCase(fetchOrderStatus.rejected, (state, action) => {
        state.status = "failed";
        state.pollingStatus = "failed";
        state.error = action.payload;
        // showErrorNotification(action.payload?.detail || 'Failed to fetch order status.');
      });
  },
});

export const { clearOrderState } = orderStatusSlice.actions;

export const selectOrderDetails = (state) => state.orderStatus.orderDetails;
export const selectPaymentLinks = (state) => state.orderStatus.paymentLinks;
export const selectOrderStatus = (state) => state.orderStatus.status;
export const selectPollingStatus = (state) => state.orderStatus.pollingStatus;
export const selectOrderError = (state) => state.orderStatus.error;
export default orderStatusSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, updateProfile } from '../redux/profileSlice';
import Layout from "./common/Layout";

export default function EditProfile() {
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.profile);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    profile_pic_image_url: 'null',
    address_line1: '',
    address_line2: '',
    state: '',
    country: '',
    pin_code: '',
  });

  useEffect(() => {
    // Fetch profile data on component mount
    dispatch(fetchProfile());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      setFormData({
        username: data.username || '',
        email: data.email || '',
        phone: data.phone || '',
        profile_pic_image_url: null,
        address_line1: data.address_line1 || '',
        address_line2: data.address_line2 || '',
        state: data.state || '',
        country: data.country || '',
        pin_code: data.pin_code || '',
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    // Ensure the file is either JPG or PNG
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setFormData({ ...formData, profile_pic_image_url: file });
    } else {
      alert("Only JPG or PNG files are allowed.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a copy of formData to prevent sending empty fields
    const updateData = new FormData();

    // Append all form fields
    updateData.append("username", formData.username);
    updateData.append("email", formData.email);
    updateData.append("phone", formData.phone);
    updateData.append("address_line1", formData.address_line1);
    updateData.append("address_line2", formData.address_line2);
    updateData.append("state", formData.state);
    updateData.append("country", formData.country);
    updateData.append("pin_code", formData.pin_code);

    // Append the profile picture only if it was selected
    if (formData.profile_pic_image_url) {
      updateData.append("profile_pic_image_url", formData.profile_pic_image_url);
    }

    // Log the form data to check in the console
    console.log("Submitting the following data:", Object.fromEntries(updateData.entries()));

    // Dispatch the update profile action and log the response to network
    dispatch(updateProfile(updateData))
      .then((response) => {
        console.log("Profile updated successfully:", response);
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
};

  if (status === 'loading') {
    return <div>Loading...</div>;  // Show loading state
  }

  if (status === 'failed') {
    return <div>Error: {error?.detail || 'Failed to update profile'}</div>;  // Show error message
  }

  return (
    <Layout>
      <div className="bg-[#F5F5F5] p-20">
        <div className="container mx-auto">
          <div className="w-[70%] mx-auto  ">
            <h2 className="font-bold text-lg">Edit Profile</h2>
            <p className="text-gray-600 text-md">You have 2 items in your cart</p>
            <div className="mt-4">
              <div className="form-card p-8 rounded-xl bg-white">
                <div className="form mt-3">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="grid gap-3 mb-5 grid-cols-2">
                      <div className="mb-3">
                        <label className="text-md font-medium">Full Name</label>
                        <input
                          type="text"
                          name="username"
                          value={formData.username}
                          onChange={handleInputChange}
                          placeholder="John Doe"
                          className="w-full border bg-[#F7F7F7] brder-gray-300 rounded pl-4 h-10 mt-2"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="text-md font-medium">Email</label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          placeholder="johndoe123@gmail.com"
                          className="w-full border bg-[#F7F7F7] brder-gray-300 rounded pl-4 h-10 mt-2"
                        />
                      </div>
                    </div>
                    <div className="grid gap-3 mb-5 grid-cols-2">
                      <div className="mb-3">
                        <label className="text-md font-medium">Mobile Number</label>
                        <input
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          placeholder="Mobile"
                          className="w-full border brder-gray-300 bg-[#F7F7F7] rounded pl-4 h-10 mt-2"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="text-md font-medium">Profile Picture</label>
                        <input
                          type="file"
                          name="profile_pic_image_url"
                          onChange={handleFileChange}  // Handle file input separately
                          accept="image/jpeg, image/png"
                          className="w-full pt-1 border brder-gray-300 bg-[#F7F7F7] rounded pl-4 h-10 mt-2"
                        />
                      </div>
                    </div>
                    <div className="grid gap-3 mb-3 grid-cols-2">
                      <div>
                        <label className="text-md font-medium">Address Line 1</label>
                        <input
                          type="text"
                          name="address_line1"
                          value={formData.address_line1}
                          onChange={handleInputChange}
                          placeholder="Address Line1"
                          className="w-full border brder-gray-300 bg-[#F7F7F7] rounded pl-4 h-10 mt-2"
                        />
                      </div>
                      <div>
                        <label className="text-md font-medium">Address Line 2</label>
                        <input
                          type="text"
                          name="address_line2"
                          value={formData.address_line2}
                          onChange={handleInputChange}
                          placeholder="Address Line2"
                          className="w-full border brder-gray-300 bg-[#F7F7F7] rounded pl-4 h-10 mt-2"
                        />
                      </div>
                    </div>
                    <div className="grid gap-3 mb-3 grid-cols-3">
                      <div className="mb-3">
                        <label className="text-md font-medium">State</label>
                        <input
                          type="text"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                          placeholder="State"
                          className="w-full border bg-[#F7F7F7] brder-gray-300 rounded pl-4 h-10 mt-2"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="text-md font-medium">Country</label>
                        <input
                          type="text"
                          name="country"
                          value={formData.country}
                          onChange={handleInputChange}
                          placeholder="Country"
                          className="w-full border bg-[#F7F7F7] brder-gray-300 rounded pl-4 h-10 mt-2"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="text-md font-medium">Pin Code</label>
                        <input
                          type="text"
                          name="pin_code"
                          value={formData.pin_code}
                          onChange={handleInputChange}
                          placeholder="Pin Code"
                          className="w-full border brder-gray-300 bg-[#F7F7F7] rounded pl-4 h-10 mt-2"
                        />
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="mt-6 rounded py-2 px-10 bg-[#5BD318] text-white font-semibold"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';

// Thunk for handling async signup request
export const signupUser = createAsyncThunk(
  'signup/signupUser',
  async ({ username, email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/user/signup/`, {
        username,
        email,
        password,
        phone: "8888888888", // Optional static phone for testing
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Network Error');
    }
  }
);

const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      
      })
      .addCase(signupUser.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload || 'Signup failed';

  // Show UI notification
  showErrorNotification(action.payload?.detail || 'Signup failed. Please try again.');
});
      
    
  },
});

export const { clearState } = signupSlice.actions;

export default signupSlice.reducer;

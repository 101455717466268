import React from "react";
import logo2 from "../assets/logo2.png";
import backgroundImage from "../assets/login-bg.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { loginUser, setInitialized } from '../redux/authSlice';
import { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import "slick-carousel/slick/slick-theme.css";
import axiosInstance from '../axios';
export default function Login() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [savedUsers, setSavedUsers] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  // New state for validation
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const savedUsersData = JSON.parse(localStorage.getItem('savedUsers')) || [];
    setSavedUsers(savedUsersData);
  }, []);

  // Validation functions
  const validateForm = () => {
    const newErrors = {};
    
    // Email validation
    if (!email) {
      newErrors.email = 'Email/Mobile Number is required';
    } else {
      // Check if it's a valid email or 10-digit phone number
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^[0-9]{10}$/;
      if (!emailRegex.test(email) && !phoneRegex.test(email)) {
        newErrors.email = 'Please enter a valid email or mobile number';
      }
    }

    // Password validation
    if (!password) {
      newErrors.password = 'Password is required';
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    return newErrors;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    // Validate form
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      // Handle remember me functionality
      if (rememberMe) {
        const userExists = savedUsers.some((user) => user.email === email);
        if (!userExists) {
          const newUser = { email, password };
          const updatedUsers = [...savedUsers, newUser];
          setSavedUsers(updatedUsers);
          localStorage.setItem('savedUsers', JSON.stringify(updatedUsers));
        }
      }
  
      // Dispatch login action and wait for the result
      const result = await dispatch(loginUser({
        username: email,
        password: password,
        grant_type: 'password',
      })).unwrap();
  
      // If login was successful, set initialized and update axios headers
      if (result && result.access_token) {
        // Set initialized state
        dispatch(setInitialized(true));
        
        // Update axios headers with the new token
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${result.access_token}`;
        
        // Navigate to home page
        navigate('/');
      }
  
    } catch (err) {
      // Handle different error cases
      let errorMessage = 'Invalid credentials';
      if (err.response) {
        switch (err.response.status) {
          case 401:
            errorMessage = 'Invalid email or password';
            break;
          case 404:
            errorMessage = 'Account not found';
            break;
          case 429:
            errorMessage = 'Too many attempts. Please try again later';
            break;
          default:
            errorMessage = 'An error occurred. Please try again';
        }
      }
      setErrors({ auth: errorMessage });
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Add this useEffect to check authentication status on component mount
  useEffect(() => {
    // Check if already authenticated and initialized
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(setInitialized(true));
      navigate('/');
    }
  }, [dispatch, navigate]);
  
  // Update the status effect to handle both status and initialization
  useEffect(() => {
    if (status === 'succeeded') {
      // Double-check we're initialized before navigating
      dispatch(setInitialized(true));
      navigate('/');
    }
  }, [status, dispatch, navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors((prev) => ({ ...prev, email: '', auth: '' }));

    if (e.target.value.length > 0) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleUserSelect = (selectedUser) => {
    setEmail(selectedUser.email);
    setPassword(selectedUser.password);
    setShowSuggestions(false);
    setErrors({});
  };

  useEffect(() => {
    if (status === 'succeeded') {
      navigate('/');
    }
  }, [status, navigate]);

  return (
    <div className="min-h-screen grid grid-cols-1 md:grid-cols-2 relative">
      <div className="bg-[#1B2D3E] flex items-center justify-center p-6 md:p-8">
        <div className="w-60% max-w-lg space-y-4">
          {/* Header section - remains the same */}
          <div className="flex justify-between mb-4 py-8">
            <div className="flex justify-start">
              <img src={logo2} className="h-8" alt="Logo" />
            </div>
            <div className="flex justify-end w-full">
              <a href="/signup">
                <button className="bg-[#5BD318] text-md px-5 py-2 rounded text-white hover:bg-green-700 font-semibold">
                  SIGN UP
                </button>
              </a>
            </div>
          </div>

          <div className="space-y-2">
            <h2 className="text-xl font-semibold text-white">Login to Stores/Admin Account</h2>
            <p className="text-sm text-gray-300">
              Login & Get redirected to stores to redeem points or the admin portal to manage rewards.
            </p>
          </div>

          {/* Show auth error if exists */}
          {errors.auth && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              <span className="block sm:inline">{errors.auth}</span>
            </div>
          )}

          <form onSubmit={handleLogin} className="space-y-4">
            <div className="relative">
              <label className="block text-white text-sm">Email/Mobile Number</label>
              <input
                placeholder="john@gmail.com"
                type="text"
                value={email}
                onChange={handleEmailChange}
                className={`w-full h-10 rounded pl-3 mt-1 bg-[#294157] border ${
                  errors.email ? 'border-red-500' : 'border-gray-600'
                } text-white text-sm`}
              />
              {errors.email && (
                <p className="text-red-500 text-xs mt-1">{errors.email}</p>
              )}
              {showSuggestions && (
                <ul className="absolute top-full left-0 right-0 bg-white text-black z-10 max-h-40 overflow-y-auto border border-gray-300 rounded">
                  {savedUsers
                    .filter((user) => user.email.toLowerCase().includes(email.toLowerCase()))
                    .map((user, index) => (
                      <li
                        key={index}
                        className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                        onClick={() => handleUserSelect(user)}
                      >
                        {user.email}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div className="relative">
              <label className="block text-white text-sm">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors((prev) => ({ ...prev, password: '', auth: '' }));
                }}
                className={`w-full h-10 rounded pl-3 pr-10 mt-1 bg-[#294157] border ${
                  errors.password ? 'border-red-500' : 'border-gray-600'
                } text-white text-sm`}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-8 transform -translate-y-1/2 cursor-pointer text-white mt-3"
              >
                {showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
              </span>
              {errors.password && (
                <p className="text-red-500 text-xs mt-1">{errors.password}</p>
              )}
            </div>

            <button 
              type="submit" 
              disabled={isSubmitting}
              className={`w-full bg-[#5BD318] text-white py-2 rounded font-semibold text-sm hover:bg-green-700 transition-all
                ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isSubmitting ? 'Logging in...' : 'Login'}
            </button>
          </form>

          <div className="flex justify-between mt-2">
            <div>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label className="text-gray-400 ml-2 text-sm">Remember me</label>
            </div>
            <a href="/forgot" className="text-[#5BD318] underline text-sm">Forgot Password?</a>
          </div>
        </div>
      </div>

      {/* Right section remains exactly the same */}
      <div
        className="relative hidden md:flex flex-col items-center justify-end bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(27, 45, 62, 0.8), rgba(27, 45, 62, 0)), url(${backgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full h-[35%] bg-opacity-60 flex items-center justify-center">
          <Slider {...settings} className="w-full">
            <div className="text-center text-white space-y-2">
              <h3 className="italic text-2xl">Get Your</h3>
              <p className="text-3xl font-semibold w-3/4 mx-auto">Voucher Redeem with us Easily</p>
            </div>
            <div className="text-center text-white space-y-2">
              <h3 className="italic text-2xl">Get Your</h3>
              <p className="text-3xl font-semibold w-3/4 mx-auto">Voucher Redeem with us Easily</p>
            </div>
            <div className="text-center text-white space-y-2">
              <h3 className="italic text-2xl">Get Your</h3>
              <p className="text-3xl font-semibold w-3/4 mx-auto">Voucher Redeem with us Easily</p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
import React, { useState } from "react";
import logo2 from "../assets/logo2.png";
import backgroundImage from "../assets/login-bg.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function OTP() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input if the current input is filled
      if (index < 3) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };
  return (
    <div className="min-h-screen flex relative">
      <div className="bg-[#1B2D3E] w-[40%]"></div>
      <div className="grow">
        <img
          src={backgroundImage}
          className="max-h-screen object-cover h-full w-full"
        />
      </div>
      <div className="absolute login-overlay h-full w-full px-20 py-10">
        <div className="flex justify-between mb-5">
          <img src={logo2} className="h-8" />
          <a href="/login">
            <button className="bg-[#5BD318] text-lg px-10 py-2 rounded text-white font-semibold">
              BACK TO LOGIN
            </button>
          </a>
        </div>
        <div className=" grid grid-cols-2">
          <div className="grid place-items-center">
            <div className="w-[60%] ml-[-80px] mt-[20px]">
              <p className="text-2xl mb-3 font-medium text-white text-center">
                OTP Verification
              </p>
              <p className="text-md text-gray-300 text-center">
                A 4-digit OTP sent to{" "}
                <span className="text-[#5BD318]"> +91 1234 5678 90</span>
              </p>
              <div className="flex justify-center gap-5 mt-5">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength="1"
                    defaultValue={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className="w-[50px] h-[50px] rounded border border-gray-600 text-center text-xl bg-[#294157]"
                  />
                ))}
              </div>

              <a href="/reset-password">
                <button className="mt-[60px] px-10 py-3 bg-[#5BD318] text-white font-semibold rounded w-full">
                  Validate
                </button>
              </a>
              <p className="mt-3 text-white text-center">
                Didn’t received the code?{" "}
                <span className="text-[#5BD318]">Send it Again</span>
              </p>
            </div>
          </div>
          <div className="relative">
            <div className="slider-container mt-[65%]">
              <Slider {...settings}>
                <div className="text-center">
                  <h3 className="italic text-2xl text-white">Get Your</h3>
                  <p className="text-3xl font-semibold text-white w-[30%] mx-auto">
                    Voucher Redeem with us Easily
                  </p>
                </div>
                <div className="text-center">
                  <h3 className="italic text-2xl text-white">Get Your</h3>
                  <p className="text-3xl font-semibold text-white w-[30%] mx-auto">
                    Voucher Redeem with us Easily
                  </p>
                </div>
                <div className="text-center">
                  <h3 className="italic text-2xl text-white">Get Your</h3>
                  <p className="text-3xl font-semibold text-white w-[30%] mx-auto">
                    Voucher Redeem with us Easily
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCoins, resetLoadCoins } from '../redux/loadcoinSlice';
import { fetchUserData } from "../redux/authSlice";
import Header from "./common/Header";
import Footer from "./common/Footer";
export default function LoadCoinsForm() {
  const dispatch = useDispatch();
  const [voucherCode, setVoucherCode] = useState("");
  const { loading, error, success, data } = useSelector((state) => state.loadCoins);
  const { user, usercoins } = useSelector((state) => state.auth);

  // Fetch user data on component mount
  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  // Reset form state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetLoadCoins());
    };
  }, [dispatch]);

  // Handle voucher code verification
  const handleVerify = async () => {
    if (!voucherCode.trim()) return;
    try {
      const result = await dispatch(loadCoins(voucherCode)).unwrap();
      if (result.status === 'success') {
        // User data will be automatically updated through the loadCoins thunk
        setVoucherCode(""); // Clear the input after success
      }
    } catch (err) {
      console.error('Failed to verify voucher:', err);
    }
  };

  // Handle coin addition
  const handleAddCoins = async (e) => {
    e.preventDefault();
    if (!voucherCode.trim()) return;
    try {
      const result = await dispatch(loadCoins(voucherCode)).unwrap();
      if (result.status === 'success') {
        // User data will be automatically updated through the loadCoins thunk
        setVoucherCode(""); // Clear the input after success
      }
    } catch (err) {
      console.error('Failed to add coins:', err);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
    <div className="bg-gray-100 min-h-[calc(100vh-220px)] flex items-start justify-center pt-16">
      <div className="bg-white rounded-lg shadow-sm w-[400px] p-8">
        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">Load Coins</h2>
            <p className="text-sm text-gray-500 mt-1">
              Enter your voucher code to add coins to your account.
            </p>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Voucher Code
              </label>
              <div className="flex gap-2">
                <input
                  type="text"
                  value={voucherCode}
                  onChange={(e) => setVoucherCode(e.target.value.toUpperCase())}
                  placeholder="Enter voucher code"
                  className="flex-1 border border-gray-300 rounded-md px-3 py-2 text-gray-800 focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                  onClick={handleVerify}
                  disabled={loading || !voucherCode.trim()}
                  className="bg-[#162533] text-white px-4 py-2 rounded-md hover:bg-[#1f3548] transition-colors"
                >
                  {loading ? "..." : "Verify"}
                </button>
              </div>
            </div>

            <button
              onClick={handleAddCoins}
              disabled={loading || !voucherCode.trim()}
              className="w-full  text-white py-2 rounded-md bg-[#5BD318] hover:bg-green-700] transition-colors "
            >
              {loading ? "Processing..." : "Add Coins"}
            </button>
          </div>

          {error && (
            <div className="p-3 bg-red-50 border border-red-100 text-red-600 rounded-md">
              {error}
            </div>
          )}
          
          {success && data && (
            <div className="p-3 bg-green-50 border border-green-100 text-green-600 rounded-md">
              <p className="font-medium">Success!</p>
              <div className="mt-2 text-sm space-y-1">
                <p>Added {data.coins_added} coins to your balance</p>
                <p>Previous balance: {data.previous_balance} coins</p>
                <p>New balance: {usercoins} coins</p>
                <p>Username: {user?.username}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
}
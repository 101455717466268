import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
import Layout from "./common/Layout";
import { fetchUserData,selectUser} from '../redux/authSlice';
import { addToCart } from '../redux/cartSlice';
import coin from "../assets/coins.png";
import { updateCartItemQuantityLocally } from '../redux/cartSlice';
export default function ShoppingCart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const product = location.state?.product;
  const [toPay, setToPay] = useState(0);
  const [quantity, setQuantity] = useState(product?.quantity || 1);
  const user = useSelector(selectUser); 
  const [selectedDenomination, setSelectedDenomination] = useState(
    product?.selectedDenomination || product?.denominations[0]?.min || 0
  );
  const [totalAmount, setTotalAmount] = useState(selectedDenomination);
  const [activeTab, setActiveTab] = useState("description");
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const productInfoRef = useRef(null);

  function stripHTML(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  useEffect(() => {
    if (!user) {
      dispatch(fetchUserData());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (productInfoRef.current) {
      productInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  useEffect(() => {
    const calculatedToPay = selectedDenomination * quantity;
    setToPay(calculatedToPay);
    setTotalAmount(calculatedToPay);
  }, [selectedDenomination, quantity]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleDenominationChange = (e) => {
    const newDenomination = Number(e.target.value);
    if (!isNaN(newDenomination)) {
      setSelectedDenomination(newDenomination);  // Update local state
      const updatedToPay = newDenomination * quantity;
      setToPay(updatedToPay);
      setTotalAmount(updatedToPay);
      
      // Update Redux locally with the selectedDenomination
      dispatch(updateCartItemQuantityLocally({
        id: product.id,
        quantity,
        to_pay: updatedToPay,
        selectedDenomination: newDenomination  // Ensure new denomination is passed
      }));
    }
  };
  const handleQuantityChange = (e) => {
    const newQuantity = Number(e.target.value);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      setQuantity(newQuantity);
      const updatedToPay = selectedDenomination * newQuantity;
      setToPay(updatedToPay);
      setTotalAmount(updatedToPay);
  
      // Dispatch the quantity update to Redux
      dispatch(updateCartItemQuantityLocally({
        id: product.id,
        quantity: newQuantity,
        to_pay: updatedToPay,
        selectedDenomination: selectedDenomination
      }));
    }
  };

  const handleAddToCart = async () => {
    setIsAddingToCart(true);
    try {
      // Add product to cart with selected denomination and quantity
      await dispatch(addToCart({
        ...product,
        selectedDenomination: selectedDenomination,
        quantity: quantity,
        to_pay: selectedDenomination * quantity  // Calculate and pass total amount
      }));
  
      // Navigate directly to the cart page and ensure the data is reflected
      navigate('/cart', {
        state: {
          product: {
            ...product,
            selectedDenomination: selectedDenomination,
            quantity: quantity,
            to_pay: selectedDenomination * quantity
          }
        }
      });
    } catch (error) {
      console.error('Error adding to cart:', error);
      alert('Error adding product to cart. Please try again.');
    } finally {
      setIsAddingToCart(false);
    }
  };
  
  if (!product) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <Layout>
      <div className="bg-gray-100 py-3 px-4">
        <div className="container mx-auto px-4">
          <h1 className="text-left font-semibold mb-4 mt-6 text-lg sm:text-2xl" style={{ fontFamily: 'Poppins' }}>
            {product.name}
          </h1>
          <p className="text-sm sm:text-base text-gray-500">
            Home / {product.subcategory?.name || 'Category'} / {product.name}
          </p>
        </div>
      </div>
      <div className="bg-gray-100 py-3 px-4">
        <div className="container mx-auto py-8 flex flex-col md:flex-row gap-10" ref={productInfoRef}>
          
          {/* Left Column */}
          <div className="md:w-2/3 bg-white p-8 rounded-lg shadow">
            <div className="flex flex-col md:flex-row mt-4 gap-8">
              {/* Product Image */}
              <div className="w-full md:w-1/2">
  {product.image_url ? (
    <img 
      src={product.image_url} 
      alt={product.name} 
      className="w-full h-auto object-contain rounded-xl" 
    />
  ) : (
    <div 
      className="w-full h-auto object-contain rounded-xl bg-white flex items-center justify-center" 
      style={{ height: "auto", minHeight: "150px" }} // Adjust minHeight as needed to match typical image height
    >
      <span className="text-gray-500">No Image Available</span>
    </div>
  )}
</div>

              {/* Product Details */}
              <div className="flex-grow">
                <h1 className="text-2xl font-bold mb-1" style={{ fontFamily: 'Poppins' }}>{product.name}</h1>
                <p className="text-sm text-gray-500 mb-6">Voucher</p>
                <div className="mb-6">
                  <label className="font-semibold block mb-2" style={{ fontFamily: 'Poppins' }}>Enter Denomination</label>
                  <div className="flex items-center gap-3">
                    <div className="relative flex-grow">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">INR</span>
                      <input
                        value={selectedDenomination}
                        onChange={handleDenominationChange}
                        className="border border-gray-500 h-10 pl-12 pr-3 rounded w-full"
                        type="number"
                        min={product?.denominations[0]?.min}
                        max={product?.denominations[0]?.max}
                        style={{ fontFamily: 'Poppins' }}
                      />
                    </div>
                    <span className="text-xl">=</span>
                    <div className="relative w-24">
                      <input
                        type="number" 
                        value={quantity}
                        onChange={handleQuantityChange}
                        className="pl-3 h-10 border border-gray-300 rounded w-full"
                        min="1"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-6">
                  <p className="text-lg font-bold mb-2" style={{ fontFamily: 'Poppins' }}>Delivery Options</p>
                  <div className="flex items-center gap-6">
                    <div className="flex items-center">
                      <input type="radio" id="gift" name="delivery_option" value="gift" className="mr-2" />
                      <label htmlFor="gift" className="text-sm">Send as Gift</label>
                    </div>
                    <div className="flex items-center">
                      <input type="radio" id="my_Account" name="delivery_option" value="my Account" className="mr-2" />
                      <label htmlFor="my_Account" className="text-sm">Add to my account (for personal use)</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column: Coin Details */}
          <div className="md:w-1/3 bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold text-gray-800 mb-2" style={{ fontFamily: 'Poppins' }}>Coin Details</h2>
            <p className="text-sm text-gray-500 mb-3">Voucher</p>
            <div className="mb-4 outline-[#ADADAD] outline-dashed outline-offset-2 p-3 rounded-md">
              <div className="flex items-center">
                <img src={coin} className="object-contain" alt="coin" />
                <span className="ml-2 text-green-500 font-medium">₹100 Coins</span>
              </div>
            </div>
            <div className="mb-4">
              <p className="text-sm text-gray-600 mb-1" style={{ fontFamily: 'Poppins' }}>To Pay</p>
              <input
                type="text"
                value={`₹ ${toPay.toFixed(2)}`}
                readOnly
                className="w-full p-2 border border-gray-300 rounded-md text-lg"
                style={{ fontFamily: 'Poppins' }}
              />
            </div>
            <p className="text-[#5BD318] text-sm mb-4">
              <span className="mr-1">✓</span>
              Saving ₹100 (You'll pay just ₹400 for this purchase)
            </p>
            
            <button 
              onClick={handleAddToCart}
              disabled={isAddingToCart}
              className={`w-full ${isAddingToCart ? 'bg-gray-400' : 'bg-[#5BD318] hover:bg-green-600'} text-white font-medium py-3 rounded-md transition duration-300`}
              style={{ fontFamily: 'Poppins' }}
            >
              {isAddingToCart ? 'Adding to Cart...' : 'Add to Cart'}
            </button>
          </div>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg mt-6">
        <div className="flex border-b-2 border-gray-200">
          <button
            onClick={() => handleTabClick("description")}
            className={`py-2 px-4 font-semibold ${activeTab === "description" ? "border-b-2 border-green-500 text-green-500" : "text-gray-500"}`}
          >
            Description
          </button>
          <button
            onClick={() => handleTabClick("terms")}
            className={`py-2 px-4 font-semibold ${activeTab === "terms" ? "border-b-2 border-green-500 text-green-500" : "text-gray-500"}`}
          >
            Terms & Conditions
          </button>
          <button
            onClick={() => handleTabClick("redeem")}
            className={`py-2 px-4 font-semibold ${activeTab === "redeem" ? "border-b-2 border-green-500 text-green-500" : "text-gray-500"}`}
          >
            How to Redeem
          </button>
        </div>

        <div className="py-6" style={{ maxWidth: '700px', wordWrap: 'break-word' }}>
          {activeTab === "description" && (
            <p className="text-sm text-gray-700" style={{ fontFamily: 'Poppins', lineHeight: '1.6' }}>
              {stripHTML(product.details)}
            </p>
          )}
          {activeTab === "terms" && (
            <p className="text-sm text-gray-700" style={{ fontFamily: 'Poppins', lineHeight: '1.6' }}>
              {stripHTML(product.terms_and_conditions)}
            </p>
          )}
          {activeTab === "redeem" && (
            <p className="text-sm text-gray-700" style={{ fontFamily: 'Poppins', lineHeight: '1.6' }}>
              {stripHTML(product.how_to_use)}
            </p>
          )}
        </div>
      </div>
    </Layout>
  );
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import { fetchUserData } from './authSlice';
import { BASE_URL } from '../config';
import { showErrorNotification } from '../components/ToastContainer';

export const loadCoins = createAsyncThunk(
  'loadCoins/redeem',
  async (couponCode, { dispatch }) => {
    try {
      // Redeem the coupon
      const response = await axiosInstance.post(`${BASE_URL}/load-coin/`, {
        coupon_code: couponCode
      });

      if (response.data.status === 'success') {
        // Fetch fresh user data using fetchUserData
        await dispatch(fetchUserData());
      }

      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'Failed to redeem coupon';
    }
  }
);

const loadcoinSlice = createSlice({
  name: 'loadCoins',
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null
  },
  reducers: {
    resetLoadCoins: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCoins.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(loadCoins.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload.data;
        state.error = null;
      })
      .addCase(loadCoins.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
       
      });
  }
});
export const { resetLoadCoins } = loadcoinSlice.actions;
export default loadcoinSlice.reducer;

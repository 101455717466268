import React, { useEffect, useState ,useRef,useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Minus, Plus, Trash2 } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from "./common/Header";
import Footer from "./common/Footer";
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner';
import { 
  fetchCartItems, 
  removeCartItem, 
  updateCartItemQuantity, 
  updateCartItemQuantityLocally
} from '../redux/cartSlice';

// Import order actions and selectors
import { 
  placeOrder, 
  selectOrderStatus, 
  selectOrderError,
  selectPaymentLinks,
  selectOrderId,
   
  // setUserCoins,
  clearOrderState,
  setOrderAmount 
} from '../redux/placeorderSlice';

// Import auth actions and selectors
import { 
  logout, 
  fetchUserData, 
  selectIsAuthenticated,
  
  refreshToken,
  selectIsInitialized
} from '../redux/authSlice';

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const initialized = React.useRef(false);
  
  // Redux selectors
  const isInitialized = useRef(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const orderStatus = useSelector(selectOrderStatus);
  const orderError = useSelector(selectOrderError);
  const paymentLinks = useSelector(selectPaymentLinks);
  const cartItems = useSelector((state) => state.cart.items);
  // Get cart state directly from state.cart
  const { items, loading, error: cartError } = useSelector((state) => ({
    items: state.cart.items || [],
    loading: state.cart.loading || false,
    error: state.cart.error
  }));
  const userCoins = useSelector(state => state.auth.usercoins || 0);
  // Get auth state
  const { token, user, usercoins } = useSelector((state) => state.auth);
  
  // Local state
  const [isUpdating, setIsUpdating] = useState(false);
  const [localError, setLocalError] = useState(null);
  const [useCoins, setUseCoins] = useState(false);
  const [toPay, setToPay] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const cartInitialized = useRef(false);
  // Effect for order success
  
  
  // Initial data fetch
  useEffect(() => {
    const initializeCart = async () => {
      // Check authentication
      if (!isAuthenticated) {
        navigate('/login');
        return;
      }

      // Only initialize once
      if (!cartInitialized.current) {
        cartInitialized.current = true;
        
        try {
          // Clean up any existing payment/order data
          localStorage.removeItem('paymentTimer');
          localStorage.removeItem('timerTimestamp');
          localStorage.removeItem('orderData');
          localStorage.removeItem('paymentStatus');
          dispatch(clearOrderState());

          // Single fetch for cart data
          await dispatch(fetchCartItems()).unwrap();
        } catch (error) {
          console.error('Cart initialization error:', error);
          setLocalError('Failed to load cart data');
        }
      }
    };

    initializeCart();

    // Cleanup function
    return () => {
      cartInitialized.current = false;
    };
  }, [isAuthenticated, dispatch, navigate]);


  const calculateItemTotal = useCallback((item) => {
    return (item.price || 0) * (item.quantity || 0);
  }, []);
  const calculateTotal = useCallback(() => {
    return items.reduce((total, item) => total + calculateItemTotal(item), 0);
  }, [items]);
  const calculateCoinDiscount = useCallback(() => {
    const total = calculateTotal();
    // Calculate 10% of order value as maximum possible discount
    const maxOrderDiscount = total * 0.1;
    
    // Check if user has enough coins
    const userAvailableCoins = userCoins || 0;
    
    if (userAvailableCoins < maxOrderDiscount) {
      setUseCoins(false);
      return 0;
    }
    
    return maxOrderDiscount;
  }, [calculateTotal, userCoins]);

  const calculateCashToPay = useCallback(() => {
    const total = calculateTotal();
    const coinDiscount = useCoins ? calculateCoinDiscount() : 0;
    return Math.max(0, total - coinDiscount);
  }, [calculateTotal, useCoins, calculateCoinDiscount]);

  const handleRemoveClick = (item) => {
    setItemToDelete(item);
    setDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        setIsUpdating(true);
        const resultAction = await dispatch(removeCartItem(itemToDelete.id));
        if (removeCartItem.fulfilled.match(resultAction)) {
          setDeleteModalOpen(false);
          setItemToDelete(null);
        }
      } catch (error) {
        setLocalError('Failed to remove item');
        console.error('Delete error:', error);
      } finally {
        setIsUpdating(false);
      }
    }
  };
  useEffect(() => {
    if (orderStatus === 'succeeded') {
      const orderData = {
        amount: calculateCashToPay(),
        paymentLinks
      };
      
      navigate('/verify-details', {
        state: orderData,
        replace: true
      });
    }
  }, [orderStatus, calculateCashToPay, paymentLinks, navigate]);

  // Handle quantity updates
  const handleUpdateQuantity = async (id, change) => {
    const item = items.find(item => item.id === id);
    if (!item) return;

    const newQuantity = Math.max(1, item.quantity + change);
    const newToPay = item.price * newQuantity;

    setIsUpdating(true);
    try {
      await dispatch(updateCartItemQuantityLocally({
        id: item.id,
        quantity: newQuantity,
        to_pay: newToPay,
        selectedDenomination: item.price
      }));

      await dispatch(updateCartItemQuantity({
        id: item.id,
        quantity: newQuantity,
        to_pay: newToPay,
        selectedDenomination: item.price
      }));
    } catch (error) {
      setLocalError('Failed to update quantity');
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle checkout
  const handleCheckout = async () => {
    if (!items || items.length === 0) {
      alert('Your cart is empty.');
      return;
    }
  
    setIsProcessing(true);
    try {
      const cashToPay = calculateCashToPay();
      const coinsToUse = useCoins ? calculateCoinDiscount() : 0;
      
      const orderPayload = {
        order_number: `ORD${Date.now()}`,
        amount: cashToPay,
        coins_used: coinsToUse, // Add coins usage information
        redirect_url: `${window.location.origin}/payment-success`,
        notify_url: `${window.location.origin}/payment-notification`
      };
  
      console.log('Placing order with payload:', orderPayload);
  
      const resultAction = await dispatch(placeOrder(orderPayload));
      
      if (placeOrder.fulfilled.match(resultAction)) {
        const paymentData = resultAction.payload;
        
        if (!paymentData.paymentLinks) {
          alert("Payment links are missing. Please try again.");
          return;
        }
      
        // Update local user coins state if coins were used
        // if (coinsToUse > 0 && paymentData.updatedCoins !== undefined) {
        //   dispatch(setUserCoins(paymentData.updatedCoins));
        // }
  
        navigate('/verify-details', {
          state: {
            orderId: paymentData.orderId,
            amount: cashToPay,
            coinsUsed: coinsToUse,
            paymentLinks: paymentData.paymentLinks,
          },
          replace: true,
        });
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setLocalError('Checkout failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  
  
  // Loading state
  if (loading) {
    return <div>Loading cart...</div>;
  }

  // Error state
  // if (cartError || localError) {
  //   return <div>Error: {cartError || localError}</div>;
  // }


  return (
    <div className="flex flex-col min-h-screen">
    <Header />
    <div className="container mx-auto p-4 bg-[#F5F5F5] min-h-screen">
    {isLoading && <LoadingSpinner />}
      {/* Delete Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center px-4">
          <div 
            className="absolute inset-0 bg-black/30 backdrop-blur-[2px]"
            onClick={() => setDeleteModalOpen(false)}
          />
          <div className="relative bg-white rounded-2xl w-full max-w-md shadow-xl">
            <div className="p-4 sm:p-6">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-medium">Delete</h3>
                <button 
                  onClick={() => setDeleteModalOpen(false)}
                  className="text-gray-400 hover:text-gray-500 text-xl p-2"
                >
                  ×
                </button>
              </div>
              <p className="text-gray-600 mb-4">Are you sure?</p>
              <p className="text-gray-500 text-sm mb-4">
                You are deleting "{itemToDelete?.product?.name}"
              </p>
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setDeleteModalOpen(false)}
                  className="px-4 py-2 text-sm border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmDelete}
                  className="px-4 py-2 text-sm text-white bg-[#82CD47] rounded-md hover:bg-[#72bd37]"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Cart Header */}
      <div className="mb-6">
        <h1 className="font-semibold mb-2 text-lg sm:text-xl" style={{ fontFamily: 'Poppins' }}>
          Shopping Cart
        </h1>
        <p className="text-sm text-gray-600" style={{ fontFamily: 'Poppins' }}>
          You have {items.length} items in your cart
        </p>
      </div>

      <div className="flex flex-col lg:flex-row gap-6">
        {/* Cart Items */}
        <div className="w-full lg:w-2/3">
          <div className="bg-white shadow rounded-lg p-4">
            {items.length === 0 ? (
              <p className="text-center py-8 text-gray-500">Your cart is empty.</p>
            ) : (
              <>
                {/* Desktop Table View */}
                <div className="hidden md:block">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b">
                        <th className="text-left py-2 font-semibold">Product</th>
                        <th className="text-center py-2 font-semibold">Quantity</th>
                        <th className="text-right py-2 font-semibold">Amount</th>
                        <th className="text-right py-2 font-semibold">To Pay</th>
                        <th className="text-right py-2 font-semibold w-12">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map(item => (
                        <tr key={item.id} className="border-b">
                          <td className="py-4">
                            <div className="flex items-start gap-4">
                              <img 
                                src={item.product?.image_url || 'placeholder.jpg'} 
                                alt={item.product?.name} 
                                className="w-24 h-24 rounded-xl object-cover"
                              />
                              <div>
                                <p className="font-semibold text-base">{item.product?.name}</p>
                                <p className="text-sm text-gray-500">Voucher</p>
                                <p className="text-sm text-gray-500">{item.product?.expiry_date}</p>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="flex items-center justify-center gap-2">
                              <button 
                                className="text-gray-500 hover:text-gray-700 p-1" 
                                onClick={() => handleUpdateQuantity(item.id, -1)}
                                disabled={item.quantity <= 1 || isUpdating}
                              >
                                <Minus className="h-4 w-4" />
                              </button>
                              <span className="w-8 text-center">
                                {item.quantity?.toString().padStart(2, '0')}
                              </span>
                              <button 
                                className="text-gray-500 hover:text-gray-700 p-1"
                                onClick={() => handleUpdateQuantity(item.id, 1)}
                                disabled={isUpdating}
                              >
                                <Plus className="h-4 w-4" />
                              </button>
                            </div>
                          </td>
                          <td className="text-right whitespace-nowrap">
                            ₹ {typeof item.price === 'number' ? item.price.toFixed(2) : '0.00'}
                          </td>
                          <td className="text-right whitespace-nowrap">
                            ₹ {typeof item.price === 'number' && item.quantity ? (item.price * item.quantity).toFixed(2) : '0.00'}
                          </td>
                          <td className="text-right">
                            <button 
                              className="text-gray-400 hover:text-red-500 p-1"
                              onClick={() => handleRemoveClick(item)}
                              disabled={isUpdating}
                            >
                              <Trash2 className="h-5 w-5" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Mobile Card View */}
                <div className="md:hidden space-y-4">
                  {items.map(item => (
                    <div key={item.id} className="border rounded-lg p-4">
                      <div className="flex gap-4 mb-4">
                        <img 
                          src={item.product?.image_url || 'placeholder.jpg'} 
                          alt={item.product?.name} 
                          className="w-24 h-24 rounded-xl object-cover"
                        />
                        <div className="flex-1">
                          <p className="font-semibold text-base mb-1">{item.product?.name}</p>
                          <p className="text-sm text-gray-500">Voucher</p>
                          <p className="text-sm text-gray-500">{item.product?.expiry_date}</p>
                        </div>
                        <button 
                          className="text-gray-400 hover:text-red-500"
                          onClick={() => handleRemoveClick(item)}
                          disabled={isUpdating}
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>

                      <div className="flex justify-between items-center border-t pt-4">
                        <div className="flex items-center gap-2">
                          <button 
                            className="text-gray-500 hover:text-gray-700 p-1"
                            onClick={() => handleUpdateQuantity(item.id, -1)}
                            disabled={item.quantity <= 1 || isUpdating}
                          >
                            <Minus className="h-4 w-4" />
                          </button>
                          <span className="w-8 text-center">
                            {item.quantity?.toString().padStart(2, '0')}
                          </span>
                          <button 
                            className="text-gray-500 hover:text-gray-700 p-1"
                            onClick={() => handleUpdateQuantity(item.id, 1)}
                            disabled={isUpdating}
                          >
                            <Plus className="h-4 w-4" />
                          </button>
                        </div>
                        <div className="text-right">
                          <div className="text-sm text-gray-500">Amount: ₹ {item.price?.toFixed(2)}</div>
                          <div className="font-semibold">Total: ₹ {(item.price * item.quantity).toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        {/* Order Summary */}
        <div className="w-full lg:w-1/3">
          <div className="bg-white shadow rounded-lg p-4 sticky top-4">
            <h2 className="font-semibold text-lg mb-4">Order Summary</h2>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <span className="text-gray-500">Net Order Value</span>
                <span className="font-medium">₹ {calculateTotal().toFixed(2)}</span>
              </div>
              <div className="flex flex-col gap-1">
      <div className="flex justify-between items-center">
        <span className="text-gray-500 text-sm">Apply Coin Discount (10%)</span>
        <label className="flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={useCoins}
            onChange={(e) => {
              const isChecked = e.target.checked;
              if (isChecked && userCoins < (calculateTotal() * 0.1)) {
                return; // Don't allow checking if not enough coins
              }
              setUseCoins(isChecked);
            }}
            className="form-checkbox h-5 w-5 text-green-500"
            disabled={calculateTotal() === 0}
          />
        </label>
      </div>
      {userCoins < (calculateTotal() * 0.1) && (
        <p className="text-red-500 text-xs">
          Insufficient coins for discount. Need {Math.ceil(calculateTotal() * 0.1)} coins.
        </p>
      )}
    </div>
    
    {useCoins && (
      <div className="flex justify-between items-center text-green-600 text-sm">
        <span>Discount Applied</span>
        <span>- ₹ {calculateCoinDiscount().toFixed(2)}</span>
      </div>
    )}

    <div className="flex justify-between items-center pt-2 border-t">
      <span className="text-gray-500">Cash to Pay</span>
      <span className="font-medium">₹ {calculateCashToPay().toFixed(2)}</span>
    </div>
  </div>
  
  <button
    onClick={handleCheckout}
    className="w-full mt-6 bg-green-500 hover:bg-green-600 text-white py-3 rounded-lg font-medium transition-colors disabled:bg-gray-400"
    disabled={isProcessing || calculateTotal() === 0}
  >
    {isProcessing ? 'Processing...' : 'Proceed to Checkout'}
  </button>

          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
};

export default Cart;
import React from "react";

import Layout from "./common/Layout";
export default function Terms() {
  return (
    <Layout>
      <div className="bg-[#F5F5F5] p-20">
        <div className="container mx-auto">
          <h3 className="text-2xl font-semibold">Terms & Conditions</h3>
          <div className="border rounded-lg border-gray-300 p-14 mt-5 bg-white">
            <h4 className="text-lg font-semibold">Introduction</h4>
            <div className="my-4">
              <p className="text-gray-600 text-md">
                Rewaards (Nreach Online Services Private Limited, a company
                incorporated under the Companies Act 1956, & Nreach Online
                Services Ltd, a company registered under the laws of India,  its
                affiliates and subsidiaries and herein after referred in short
                “Rewaards”. ("Rewaards”, "we," "us," and/or "our"). For
                Rewaards, registered users, the website, together with Rewaards
                mobile app (the “App”) and application programming interface
                (the “API”) all together referred as Service, also provides a
                platform and tools for using the Service and is considered a
                part of the Service.
              </p>
            </div>
            <h4 className="text-lg font-semibold">
              Acceptance of terms & conditions of service
            </h4>
            <div className="my-4">
              <p className="text-gray-600 text-md">
                provides the website and the Service, including the APP, the API
                and any functionality services offered on or through the website
                and the Service, to you subject to these Terms and Conditions of
                Service (this “TOS”). This TOS includes Xoxoday Privacy Policy,
                which explains how Xoxoday collects, uses and protects data from
                visitors to the website and users of the Service. If you do not
                agree with any part of this TOS, you may not use the website or
                the Service in any way. Your use of the website indicates your
                acceptance of this TOS. Use of the App and certain portions of
                the website are only available to Xoxoday registered users. If
                you are a Xoxoday registered user, your use of the Site and the
                Service is conditioned on your acceptance of these TOS. For the
                purpose of this Agreement, the term, “Personal Information”
                shall mean and include, without limitation, personal details
                such as name, age, address, and employment details and such
                Personal Information may also include passwords, details of bank
                account, credit card, or debit card or other details of payment
                instrument (collectively, “Financial Information”) as may be
                obtained from you for facilitating online payments during your
                usage of the website. If your organization has entered into a
                commercial agreement with Xoxoday, including any agreement or
                addendum related to the protection of personal data (a “Customer
                Agreement”), then (i) the terms of the Customer Agreement govern
                the relationship between your organization and Xoxoday, and this
                TOS governs your individual use of the Site and the Service, and
                (ii) if there is any conflict between this TOS and the Customer
                Agreement, the Customer Agreement will take precedence over this
                TOS.
              </p>
            </div>
            <h4 className="text-lg font-semibold">Introduction</h4>
            <div className="my-4">
              <p className="text-gray-600 text-md">
                Rewaards (Nreach Online Services Private Limited, a company
                incorporated under the Companies Act 1956, & Nreach Online
                Services Ltd, a company registered under the laws of India,  its
                affiliates and subsidiaries and herein after referred in short
                “Rewaards”. ("Rewaards”, "we," "us," and/or "our"). For
                Rewaards, registered users, the website, together with Rewaards
                mobile app (the “App”) and application programming interface
                (the “API”) all together referred as Service, also provides a
                platform and tools for using the Service and is considered a
                part of the Service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

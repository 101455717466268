import React, { useState, useEffect } from "react";
import logo2 from "../assets/logo2.png";
import backgroundImage from "../assets/login-bg.png";
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { signupUser, clearState } from '../redux/signupSlice';
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

// Custom Alert component for success message
const Alert = ({ children }) => (
  <div className="fixed top-0 left-0 right-0 z-50 bg-green-500 text-white p-4 text-center">
    {children}
  </div>
);

export default function Signup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, success } = useSelector((state) => state.signup);

  useEffect(() => {
    if (success) {
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        dispatch(clearState());  // Clear the state before redirecting
        navigate("/login");  // Redirect after clearing the state
      }, 3000);
    }
  }, [success, navigate, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Name validation
    if (name.trim() === "") {
        alert("Full Name is required!");
        return;
    }

    // Email validation (simple regex for basic format check)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        alert("Please enter a valid email address!");
        return;
    }

    // Password length validation
    if (password.length < 6) {
        alert("Password must be at least 6 characters long!");
        return;
    }

    // Confirm password validation
    if (password !== confirmPassword) {
        alert("Passwords do not match!");
        return;
    }

    // Proceed with dispatch if all validations pass
    dispatch(signupUser({ username: name, email, password }));
};

  const renderError = (error) => {
    if (typeof error === 'string') return error;
    if (typeof error === 'object') {
      return Object.entries(error).map(([key, value], index) => (
        <p key={index} className="text-red-500">
          {`${key}: ${value}`}
        </p>
      ));
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  return (
    <div className="min-h-screen grid grid-cols-1 md:grid-cols-2 relative">
      {/* Success Popup */}
      {showSuccessPopup && (
        <Alert>
          <strong>Signup Successful!</strong> Redirecting to login page...
        </Alert>
      )}

      {/* Left Section: Signup Form */}
      <div className="bg-[#1B2D3E] flex items-center justify-center p-6 md:p-8">
        <div className="w-60% max-w-lg space-y-4">
          <div className="flex justify-between mb-4 py-8">
            <div className="flex justify-start">
              <img src={logo2} className="h-8" alt="Logo" />
            </div>
            <div className="flex justify-end w-full">
              <a href="/login">
                <button className="bg-[#5BD318] text-md px-5 py-2 rounded text-white hover:bg-green-700 font-semibold">
                  SIGN IN
                </button>
              </a>
            </div>
          </div>

          <div className="space-y-2">
            <h2 className="text-xl font-semibold text-white">Register</h2>
            <p className="text-sm text-gray-300">
              Signup & Get redirected to stores to redeem points or the admin portal to manage rewards.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-white text-sm">Full Name</label>
              <input
                placeholder="John Doe"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full h-10 rounded pl-3 mt-1 bg-[#294157] border border-gray-600 text-white text-sm"
              />
            </div>
            <div>
              <label className="block text-white text-sm">Email/Mobile Number</label>
              <input
                placeholder="john@gmail.com"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full h-10 rounded pl-3 mt-1 bg-[#294157] border border-gray-600 text-white text-sm"
              />
            </div>
            <div className="relative">
              <label className="block text-white text-sm">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-10 rounded pl-3 mt-1 bg-[#294157] border border-gray-600 text-white text-sm"
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-10 transform -translate-y-1/2 cursor-pointer text-white"
              >
                {showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
              </span>
            </div>
            <div className="relative">
              <label className="block text-white text-sm">Confirm Password</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full h-10 rounded pl-3 mt-1 bg-[#294157] border border-gray-600 text-white text-sm"
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-10 transform -translate-y-1/2 cursor-pointer text-white"
              >
                {showConfirmPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
              </span>
            </div>

            {/* Error display */}
            {error && (
              <div className="text-red-500 text-sm">
                {renderError(error)}
              </div>
            )}

            <button type="submit" className="w-full bg-[#5BD318] hover:bg-green-700 text-white py-2 rounded font-semibold text-sm">
              {loading ? 'Registering...' : 'Register Now'}
            </button>
          </form>
        </div>
      </div>

      {/* Right Section: Image & Slider */}
      <div
        className="relative hidden md:flex flex-col items-center justify-end bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(27, 45, 62, 0.8), rgba(27, 45, 62, 0)), url(${backgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full h-[35%] bg-opacity-60 flex items-center justify-center">
          <Slider {...settings} className="w-full">
            <div className="text-center text-white space-y-2">
              <h3 className="italic text-2xl">Get Your</h3>
              <p className="text-3xl font-semibold w-3/4 mx-auto">Voucher Redeem with us Easily</p>
            </div>
            <div className="text-center text-white space-y-2">
              <h3 className="italic text-2xl">Get Your</h3>
              <p className="text-3xl font-semibold w-3/4 mx-auto">Voucher Redeem with us Easily</p>
            </div>
            <div className="text-center text-white space-y-2">
              <h3 className="italic text-2xl">Get Your</h3>
              <p className="text-3xl font-semibold w-3/4 mx-auto">Voucher Redeem with us Easily</p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

import {
  fetchOrderStatus,
  selectOrderDetails,
  selectOrderStatus, 
  selectOrderError, 
  selectPaymentLinks,
  selectPollingStatus,

  


} from "../redux/orderstatusSlice";
import React, { useState,useEffect,useCallback } from "react";
import check from "../assets/gg_check.svg";
import paytmImg from "../assets/Group.png";
import gPayImg from "../assets/gpay.png";
import phonePayImg from "../assets/phone-pay.png";
import wpImg from "../assets/wp.png";
import otherImg from "../assets/wallet.png";
import bhimImg from "../assets/bhim.png";
import joy from "../assets/voucher/joy.svg";
import voucherImg from "../assets/voucher/voucher.svg";
import congratsImg from "../assets/congrats.png";
import coin from "../assets/coin.png";
import check2 from "../assets/check2.png";
import stars from "../assets/stars.png";
import loading from "../assets/loading.png";
import { useLocation,useNavigate } from "react-router-dom";
import { fetchUserData } from "../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import { 
//   placeOrder, 
  
//   selectOrderError, 
//   selectPaymentLinks,
//   selectOrderAmount,
//   setOrderAmount,
//   clearOrderState,
//   selectOrderId,
//   selectOrderLink ,
//   getOrderStatus,
//   selectOrderDetails,
// } from "../redux/placeorderSlice";
import {
  Box,
  Typography,
  Button,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Input,
  TextField,
  LinearProgress,  // Add this import
  CircularProgress
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:"20px",
  textAlign:"center",
  p: 4,
};




function SuccessModal({ open, close }) {
  // Add countdown display
 
  return (
    <Modal open={open} onClose={close} aria-labelledby="modal-success-title">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: "20px",
        textAlign: "center",
        p: 4,
      }}>
        {/* <CloseIcon sx={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }} onClick={close} /> */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={congratsImg} alt="Congratulations" />
        </Box>
        <Typography fontWeight={600}>Congrats</Typography>
        <Typography>Points has been added on your account</Typography>
        <Box sx={{ 
          border: "1px dashed #ABB7C2", 
          borderRadius: "6px", 
          display: "flex", 
          justifyContent: "space-between", 
          p: 1, 
          mt: 1 
        }}>
          <Typography 
            sx={{ alignSelf: "center", letterSpacing: "3px", color: "#A5B7C9" }} 
            fontWeight={600}
          >
            0121DARO2
          </Typography>
          <Button 
            sx={{ 
              backgroundColor: "#26CD18", 
              width: "100px", 
              fontSize: "12px", 
              color: "white",
              '&:hover': {
                backgroundColor: "#1ea513"
              }
            }}
          >
            Redeem
          </Button>
        </Box>

        <Box sx={{ borderTop: "1px dashed #ABB7C2", mt: 4, pt: 4 }}>
          <Box className="reward-bg" sx={{
            p: 1,
            position: "relative",
            borderRadius: "8px",
            height: "60px",
            display: "flex",
            justifyContent: "space-between"
          }}>    
            <Box sx={{ position: "absolute", top: "-5px", right: "-5px", zIndex: 5 }}>
              <img src={stars} alt="Stars" />
            </Box>
            <Box sx={{ display: "flex", gap: "10px", textAlign: "left" }}>
              <img src={check2} style={{ width: "35px", objectFit: "contain" }} alt="Check" />
              <div>
                <Typography fontWeight={600} fontSize={20} color="#64DC18">1000</Typography>
                <Typography color="#fff" fontSize={12}>Coins Redeemed on this site</Typography>
              </div>
            </Box>
            <Box>
              <img src={coin} style={{ width: "50px" }} alt="Coin" />
            </Box>
          </Box>
        </Box>
        <Button
          fullWidth
          onClick={close}
          sx={{
            background: "linear-gradient(360deg, #26CD18 0%, #74E018 100%)",
            mx: "auto",
            borderRadius: "32px",
            py: 1,
            mt: 2,
            textTransform: "capitalize",
            '&:hover': {
              background: "linear-gradient(360deg, #1ea513 0%, #5ec108 100%)"
            }
          }}
        >
          <Typography fontSize={16} fontWeight={700} color="#fff">
            OK
          </Typography>
        </Button>
      </Box>
    </Modal>
  );
}


function DetailsModal({open, close}) {

    return (
      <div>
        <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title1"
          aria-describedby="modal-modal-description1"
        >
          <Box sx={style}>
              <CloseIcon sx={{position:"absolute", top:10, right:10, cursor:"pointer"}} onClick={close}/>
                <Typography fontSize={18} fontWeight={600} textAlign="left">Please fill the following details</Typography>
                <Box sx={{mt:3, display:"flex", flexDirection:"column", gap:"10px"}}>
                    <Box sx={{textAlign:"left"}}>
                        <Typography color="#162533" fontSize={14} mb={1}>Name</Typography>
                        <TextField id="outlined-basic" placeholder="John Doe" label="" variant="outlined"  fullWidth />
                    </Box>
                    <Box sx={{textAlign:"left"}}>
                        <Typography color="#162533" fontSize={14}  mb={1}>Mobile Number</Typography>
                        <TextField id="outlined-basic" label="" placeholder="90738267627" variant="outlined" fullWidth />
                    </Box>
                    <Box sx={{textAlign:"left"}}>
                        <Typography color="#162533" fontSize={14}  mb={1}>Email</Typography>
                        <TextField id="outlined-basic" label="" placeholder="johndoe@gmail.com" variant="outlined"  fullWidth />
                    </Box>
                </Box>
                <Button
          fullWidth
              sx={{
                background: "linear-gradient(360deg, #26CD18 0%, #74E018 100%)",
                mx: "auto",
                borderRadius: "32px",
                py: 1,
                mt:2,
                textTransform: "capitalize",
              }}
            >
              <Typography fontSize={16} fontWeight={700} color="#fff" variant="p">
                OK
              </Typography>
            </Button>
          </Box>
        </Modal>
      </div>
    );
  }

// function BasicModal({open, close}) {

//     return (
//       <div>
//         <Modal
//           open={open}
//           onClose={close}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={style}>
//               <CloseIcon sx={{position:"absolute", top:10, right:10, cursor:"pointer"}} onClick={close}/>
//             <Box sx={{display:"flex", justifyContent:"center"}}>
//               <img src={congratsImg} />
//             </Box>
//             <Typography fontWeight={600}>Congrats</Typography>
//             <Typography>Points has been added on your account</Typography>
//             <Box sx={{border: "1px dashed #ABB7C2", borderRadius:"6px", display:"flex", justifyContent:"space-between", p:1, mt:1}}>
//               <Typography sx={{alignSelf:"center", letterSpacing:"3px", color:"#A5B7C9"}} fontWeight={600}>0121DARO2</Typography>
//               <Button sx={{backgroundColor:"#26CD18", width:"100px", fontSize:"12px", color:"white"}}>Redeem</Button>
//             </Box>
  
//             <Box sx={{borderTop:"1px dashed #ABB7C2", mt:4, pt:4,}}>
//               <Box className="reward-bg" sx={{p:1,position:"relative", borderRadius:"8px", height:"60px", display:"flex", justifyContent:"space-between"}}>    
//                   <Box sx={{position:"absolute", top:"-5px", right:"-5px", zIndex:5}}>
//                       <img src={stars}/>
//                   </Box>
//                   <Box sx={{display:"flex", gap:"10px", textAlign:"left"}}>
//                       <img src={check2} style={{width:"35px", objectFit:"contain"}}/>
//                       <div>
//                           <Typography fontWeight={600} fontSize={20} color="#64DC18">1000</Typography>
//                           <Typography color="#fff" fontSize={12}>Coins Redeemed on this site</Typography>
//                       </div>
//                   </Box>
//                   <Box>
//                       <img src={coin} style={{width:"50px"}}/>
//                   </Box>
                  
                  
//               </Box>
//             </Box>
//             <Button
//           fullWidth
//               sx={{
//                 background: "linear-gradient(360deg, #26CD18 0%, #74E018 100%)",
//                 mx: "auto",
//                 borderRadius: "32px",
//                 py: 1,
//                 mt:2,
//                 textTransform: "capitalize",
//               }}
//             >
//               <Typography fontSize={16} fontWeight={700} color="#fff" variant="p">
//                 OK
//               </Typography>
//             </Button>
//           </Box>
//         </Modal>
//       </div>
//     );
//   }
  

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs.toString().padStart(2, "0")}`;
};

export default function RoutePayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [redirectUrl, setRedirectUrl] = useState("");

    const [detailsOpen, setDetailsOpen] = useState(false);  // for boolean state
    const [formData, setFormData] = useState({
        username: '',
        phone: '',
        email: ''
      });// for object state
    const [isLoading, setIsLoading] = useState(false);  // for loading state
    const [isNavigating, setIsNavigating] = useState(false);  // to handle navigation state
     // 3-minute timer
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
      
    let  order_id = location.state?.orderId || ""; // dynamically set if available
    const [successOpen, setSuccessOpen] = useState(false);
    const orderDetails = useSelector(selectOrderDetails);
    const paymentLinks = useSelector(selectPaymentLinks);
    const orderStatus = useSelector(selectOrderStatus);
    const error = useSelector(selectOrderError);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const pollingStatus = useSelector(selectPollingStatus);
    const [selectedPayment, setSelectedPayment] = useState(null);
    
  
      // useEffect(() => {
      //   // Retrieve saved timer and timestamp from localStorage
      //   const savedTimer = localStorage.getItem("paymentTimer");
      //   const savedTimestamp = localStorage.getItem("timerTimestamp");
    
      //   if (savedTimer && savedTimestamp) {
      //     const elapsed = Math.floor((Date.now() - parseInt(savedTimestamp)) / 1000);
      //     const remainingTime = Math.max(0, parseInt(savedTimer) - elapsed);
      //     setPaymentTimer(remainingTime > 0 ? remainingTime : 180);
      //   } else {
      //     // If no saved values, set initial timer and save timestamp
      //     localStorage.setItem("paymentTimer", 180);
      //     localStorage.setItem("timerTimestamp", Date.now());
      //     setPaymentTimer(180);
      //   }
    
        // Set up the timer interval
        const [paymentTimer, setPaymentTimer] = useState(() => {
          const savedTimer = sessionStorage.getItem("paymentTimer");
          const savedTimestamp = sessionStorage.getItem("timerTimestamp");
          
          if (savedTimer && savedTimestamp) {
              const elapsed = Math.floor((Date.now() - parseInt(savedTimestamp)) / 1000);
              const remainingTime = Math.max(0, parseInt(savedTimer) - elapsed);
              return remainingTime > 0 ? remainingTime : 180;
          }
          
          // If no saved state, initialize timer and timestamp
          const initialTime = 180;
          sessionStorage.setItem("paymentTimer", initialTime.toString());
          sessionStorage.setItem("timerTimestamp", Date.now().toString());
          return initialTime;
      });
      // useEffect(() => { 
      //   let urlParams = new URLSearchParams(window.location.search); 
      //   order_id = urlParams.get('orderid');
      //   console.log(order_id)
      // }, []);
      
      useEffect(() => {
        
          setFormData({
            username: orderDetails?.user.username || "Loading...",
            email:orderDetails?.user.email || "Loading...",
            phone: orderDetails?.user.phone || "Loading..."
          });
        
      }, [ dispatch]);

      // Timer effect
      useEffect(() => {
        let timerInterval;
      
        if (paymentTimer > 0) {
          timerInterval = setInterval(() => {
            setPaymentTimer((prevTime) => {
              const newTime = prevTime - 1;
      
              // When timer reaches 0
              if (newTime <= 0) {
                clearInterval(timerInterval);
                sessionStorage.removeItem("paymentTimer");
                sessionStorage.removeItem("timerTimestamp");
                
                // Get the redirect URL from orderDetails
                const redirectTo = orderDetails?.redirectUrl || "https://www.instagram.com/";
                
                console.log("Timer expired, redirecting to:", redirectTo);
                
                // Direct redirect without showing success modal
                window.location.href = redirectTo;
                return 0;
              }
      
              // Update session storage
              sessionStorage.setItem("paymentTimer", newTime.toString());
              sessionStorage.setItem("timerTimestamp", Date.now().toString());
              return newTime;
            });
          }, 1000);
        }
      
        return () => {
          if (timerInterval) {
            clearInterval(timerInterval);
          }
        };
      }, [orderDetails?.redirectUrl, paymentTimer]);

      
        // ... other code ...
      
        const handleBackToCart = () => {
          // Clear timer state when navigating back to cart
          sessionStorage.removeItem("paymentTimer");
          sessionStorage.removeItem("timerTimestamp");
          navigate("/cart");
        };
      
        useEffect(() => {
          const urlParams = new URLSearchParams(window.location.search);
          const currentOrderId = urlParams.get('orderid') || order_id;
        
          if (!currentOrderId) {
            console.log('No order ID available');
            return;
          }
        
          const fetchStatus = async () => {
            try {
              const response = await dispatch(fetchOrderStatus(currentOrderId));
              
              if (response.type === fetchOrderStatus.fulfilled.type) {
                const { payload } = response;
                console.log('Payment status:', payload?.status);
                
                // Only open success modal if status is success
                if (payload?.status === 'success') {
                  // Store the redirect URL
                  if (payload.redirectUrl) {
                    setRedirectUrl(payload.redirectUrl);
                  }
                  // Show success modal
                  setSuccessOpen(true);
                  
                  // Clear timer and storage
                  sessionStorage.removeItem('paymentTimer');
                  sessionStorage.removeItem('timerTimestamp');
                  sessionStorage.removeItem('orderData');
                  
                  // Stop polling
                  return true;
                }
                
                // Continue polling if status is not success
                if (payload?.status === 'PAYMENT_PENDING') {
                  console.log('Payment still pending, continuing to poll...');
                  return false;
                }
              }
              return false;
            } catch (error) {
              console.error('Error checking order status:', error);
              return false;
            }
          };
        
          // Initial fetch
          if (isInitialLoad) {
            fetchStatus();
            setIsInitialLoad(false);
          }
        
          // Start polling interval
          const pollInterval = setInterval(fetchStatus, 2000);
        
          return () => {
            console.log('Component unmounting, clearing interval');
            clearInterval(pollInterval);
          };
        }, [dispatch, order_id, navigate, isInitialLoad]);
      
        
       
        useEffect(() => {
          let redirectTimer;
        
          if (successOpen && redirectUrl) {
            console.log("Starting redirect timer after success");
            
            redirectTimer = setTimeout(() => {
              console.log("Executing redirect to:", redirectUrl);
              setSuccessOpen(false);
              
              // Clear stored data
              sessionStorage.removeItem("paymentTimer");
              sessionStorage.removeItem("timerTimestamp");
              sessionStorage.removeItem("orderData");
              
              // Handle redirect
              window.location.href = redirectUrl;
            }, 4000);
          }
        
          return () => {
            if (redirectTimer) {
              console.log("Clearing redirect timer");
              clearTimeout(redirectTimer);
            }
          };
        }, [successOpen, redirectUrl]);

    const handlePayment = (link, method) => {
      if (!selectedPayment) {
        alert("Please select a payment method first");
        return;
      }
    
      if (!isTermsAccepted) {
        alert("Please accept the terms and conditions.");
        return;
      }
    
      if (selectedPayment.link) {
        window.open(selectedPayment.link, "_blank");
      } else {
        alert(`${selectedPayment.name} payment option is currently unavailable.`);
      }
    };
  
    if (orderStatus === "loading") {
      return (
        <Box sx={{ textAlign: "center", mt: 5 }}>
          <CircularProgress />
          <Typography mt={2}>Loading Order Details...</Typography>
        </Box>
      );
    }
  
    if (orderStatus === "failed") {
      return (
        <Box sx={{ textAlign: "center", mt: 5 }}>
          <Typography color="error">{error || "Failed to load order details."}</Typography>
          <Button onClick={() => navigate("/")}>Go Back</Button>
        </Box>
      );
    }
    const handleSuccessClose = () => {
      setSuccessOpen(false);
      // Clear stored data
      sessionStorage.removeItem("paymentTimer");
      sessionStorage.removeItem("timerTimestamp");
      sessionStorage.removeItem("orderData");
      
      // Get redirect URL from response or use default
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        // Fallback to default URL if no redirect URL is available
        window.location.href = "https://www.rewaards.io/payment?orderid=NDQz";
      }
    };
    const progressStyles = {
      root: {
        height: 6,
        borderRadius: 3,
        backgroundColor: '#F5F5F5', // Light grey background
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#4FCD18',
          borderRadius: 3,
          transition: 'transform 0.4s linear', // Smooth animation
        }
      }
    };
    const paymentMethods = [
      {
        id: 'phonepe',
        name: "Phonepay",
        link: paymentLinks.phonePe,
        icon: <img src={phonePayImg} alt="PhonePe" style={{ width: "32px", height: "32px" }} />
      },
      {
        id: 'paytm',
        name: "Paytm",
        link: paymentLinks.paytm,
        icon: <img src={paytmImg} alt="Paytm" style={{ width: "47px", height: "20px" }} />
      },
      {
        id: 'gpay',
        name: "G Pay",
        link: paymentLinks.gpay,
        icon: <img src={gPayImg} alt="Google Pay" style={{ width: "32px", height: "32px" }} />
      },
      {
        id: 'other',
        name: "Other",
        link: paymentLinks.other,
        icon: <img src={otherImg} alt="Other" style={{ width: "32px", height: "32px" }} />
      }
    ];
    const handlePaymentMethodSelect = (method) => {
      setSelectedPayment(method);
    };
    return (
      <>
        {/* <BasicModal open={open} close={handleClose} /> */}
        
        <SuccessModal open={successOpen} close={handleSuccessClose} />
        <Card
          elevation={0}
          sx={{
            boxShadow: "0px 4px 18.8px -5px #50A63B59",
            borderRadius: "25px",
            mt: 4,
            width: "450px",
            mx: "auto",
            "@media (max-width:768px)": {
              width: "90%",
              boxShadow: "none",
              mt: 0,
            },
          }}
        >
          <Box
            sx={{
              p: 5,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              "@media (max-width:768px)": {
                p: 3,
              },
            }}
          >

<Box
  onClick={!isNavigating ? handleBackToCart : undefined}
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: isNavigating ? 'not-allowed' : 'pointer',
    marginBottom: 2,
    opacity: isNavigating ? 0.6 : 1, // Reduce opacity during buffering
  }}
>
  {isNavigating ? (
    <CircularProgress size={20} sx={{ color: '#4FCD18', mr: 1 }} />
  ) : (
    <ArrowBackIcon />
  )}
  <Typography fontSize={14} fontWeight={600} ml={1}>
    Back to Cart
  </Typography>
</Box>
        {/* {isCheckingStatus && (
            <Box sx={{ mb: 2, textAlign: "center" }}>
              <Typography variant="h6" color="primary">
                Payment in Progress
              </Typography>
              <Typography color="error">Time remaining: {formatTime(paymentTimer)}</Typography>
              <LinearProgress
                variant="determinate"
                value={(paymentTimer / 180) * 100}
                sx={{
                  ...progressStyles.root,
                  "& .MuiLinearProgress-bar": progressStyles.bar,
                  mt: 1
                }}
              />
            </Box> */}
            <Box 
  sx={{ 
    mb: 2.5,
    textAlign: 'center',
    maxWidth: '100%',
  }}
>
  <Typography 
    sx={{
      fontSize: '18px',
      color: '#4fcd18', // Dark text color for "Complete Payment"
      fontWeight: 600,
      mb: 1
    }}
  >
    Complete Payment
  </Typography>
  <Typography 
    sx={{
      color: '#728393', // Subtle grey for "Time remaining"
      fontSize: '14px',
      mb: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
      '& span': {
        color: '#F44336', // Keep timer in red but slightly muted
        fontWeight: 500
      }
    }}
  >
    Time remaining: <span>{formatTime(paymentTimer)}</span>
  </Typography>
  <Box sx={{ px: 0.5 }}> {/* Slight padding for progress bar */}
    <LinearProgress 
      variant="determinate" 
      value={(paymentTimer / 180) * 100} 
      sx={progressStyles.root}
    />
  </Box>
</Box>
 
            <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography fontWeight={600} sx={{ "@media (max-width:768px)": { fontSize: 22 } }}>
                Verify your details
              </Typography>
              <Typography fontSize={14} fontWeight={600}>
                Your voucher will be sent to you on
              </Typography>
              <Typography color="#64B142" fontWeight={600} fontSize={12}>
                SMS, Whatsapp, Email
              </Typography>
            </Box>
            {/* Editable Details Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#F1F1F1",
                  p: 2,
                  flexGrow: "1",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography fontSize={12} color="#728393">Name</Typography>
                  <Box>
                    <BorderColorIcon htmlColor="#4FCD18" onClick={() => setDetailsOpen(true)} />
                  </Box>
                </Box>
                <Typography fontWeight={600}>{orderDetails?.user.username}</Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#F1F1F1",
                  p: 2,
                  flexGrow: "1",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography fontSize={12} color="#728393">Phone No.</Typography>
                  <Box>
                    <BorderColorIcon htmlColor="#4FCD18" onClick={() => setDetailsOpen(true)} />
                  </Box>
                </Box>
                <Typography fontWeight={600}>{orderDetails?.user.phone}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#F1F1F1",
                  p: 2,
                  flexGrow: "1",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography fontSize={12} color="#728393">Email</Typography>
                  <Box>
                    <BorderColorIcon htmlColor="#4FCD18" onClick={() => setDetailsOpen(true)} />
                  </Box>
                </Box>
                <Typography fontWeight={600}>{orderDetails?.user.email}</Typography>
              </Box>
            </Box>
            <FormControlLabel
  control={
    <Checkbox
      checked={isTermsAccepted}
      onChange={(e) => setIsTermsAccepted(e.target.checked)}
      color="primary"
      size="small"
    />
  }
  sx={{ 
    '& .MuiFormControlLabel-label': { 
      fontSize: '10px',
      color: '#728393'
    }
  }}
  label="I accept Terms and Conditions and allow redeeming 1000 coins to this site after payment."
/>
            {/* Payment Methods */}
            <Box sx={{ mt: 2 }}>
  <Typography fontWeight={600} textAlign="left" mb={2}>
    Payment Method
  </Typography>
  
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: "8px",
      mb: 2
    }}
  >
    {paymentMethods.map((method) => (
      <Box
        key={method.id}
        onClick={() => method.link && handlePaymentMethodSelect(method)}
        sx={{ 
          cursor: method.link ? "pointer" : "not-allowed",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4px"
        }}
      >
        <Box
          sx={{
            width: "64px",
            height: "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: selectedPayment?.id === method.id ? "#F0F7FF" : "#F5F5F5",
            borderRadius: "12px",
            border: selectedPayment?.id === method.id ? "2px solid #4FCD18" : "1px solid #EAEAEA",
            transition: "all 0.2s ease",
            '&:hover': {
              backgroundColor: method.link ? "#F0F7FF" : "#F5F5F5",
              transform: method.link ? "scale(1.05)" : "none"
            }
          }}
        >
          {method.icon}
        </Box>
        <Typography 
          fontSize="12px"
          color="#666"
          textAlign="center"
          sx={{
            color: selectedPayment?.id === method.id ? "#4FCD18" : "#666",
            fontWeight: selectedPayment?.id === method.id ? 600 : 400
          }}
        >
          {method.name}
        </Typography>
      </Box>
    ))}
  </Box>
</Box>

          {/* Make Payment Button */}
          <Button
  onClick={handlePayment}
  disabled={isLoading || !selectedPayment}
  fullWidth
  sx={{
    background: !selectedPayment 
      ? "#E0E0E0"
      : isLoading 
        ? "#ccc" 
        : "linear-gradient(360deg, #26CD18 0%, #74E018 100%)",
    mx: "auto",
    borderRadius: "32px",
    py: 1,
    mt: 2,
    textTransform: "capitalize",
    "@media (max-width:768px)": { mt: 10 },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      color: '#9E9E9E'
    }
  }}
>
  <Typography 
    fontSize={16} 
    fontWeight={700} 
    color={!selectedPayment ? "#9E9E9E" : "#fff"} 
    variant="p"
  >
    {isLoading ? "Processing..." : 
     !selectedPayment ? "Select a payment method" :
     `Pay ₹${orderDetails?.amount}.00 with ${selectedPayment.name}`}
  </Typography>
</Button>
        </Box>
        </Card>
      </>
    );
  }


import React, { useState,useEffect,useCallback } from "react";
import check from "../../assets/gg_check.svg";
import paytmImg from "../../assets/Group.png";
import gPayImg from "../../assets/gpay.png";
import phonePayImg from "../../assets/phone-pay.png";
import wpImg from "../../assets/wp.png";
import otherImg from "../../assets/wallet.png";
import bhimImg from "../../assets/bhim.png";
import joy from "../../assets/voucher/joy.svg";
import voucherImg from "../../assets/voucher/voucher.svg";
import congratsImg from "../../assets/congrats.png";
import coin from "../../assets/coin.png";
import check2 from "../../assets/check2.png";
import stars from "../../assets/stars.png";
import loading from "../../assets/loading.png";
import { useLocation,useNavigate } from "react-router-dom";
import { fetchUserData } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { XCircle } from 'lucide-react';
import { 
  placeOrder, 
  selectOrderStatus, 
  selectOrderError, 
  selectPaymentLinks,
  selectOrderAmount,
  setOrderAmount,
  clearOrderState,
  selectOrderId,
  selectOrderLink ,
  getOrderStatus,
  selectOrderDetails,
} from "../../redux/placeorderSlice";
import {
  Box,
  Typography,
  Button,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Input,
  TextField,
  LinearProgress,  // Add this import
  CircularProgress
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import axiosInstance from "../../axios";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:"20px",
  textAlign:"center",
  p: 4,
};


const PhonePeIcon = () => (
  <svg viewBox="0 0 40 40" className="w-10 h-10">
    <rect width="40" height="40" rx="8" fill="#5F259F"/>
    <path d="M20 7.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5 12.5-5.596 12.5-12.5S26.904 7.5 20 7.5zm5.625 13.75h-2.5v3.75c0 .345-.28.625-.625.625h-5c-.345 0-.625-.28-.625-.625v-3.75h-2.5c-.345 0-.625-.28-.625-.625v-5c0-.345.28-.625.625-.625h2.5v-1.25c0-.345.28-.625.625-.625h5c.345 0 .625.28.625.625v1.25h2.5c.345 0 .625.28.625.625v5c0 .345-.28.625-.625.625z" fill="white"/>
  </svg>
);

const PaytmIcon = () => (
  <svg viewBox="0 0 40 40" className="w-10 h-10">
    <rect width="40" height="40" rx="8" fill="#00BAF2"/>
    <path d="M12.5 10h15c1.38 0 2.5 1.12 2.5 2.5v15c0 1.38-1.12 2.5-2.5 2.5h-15c-1.38 0-2.5-1.12-2.5-2.5v-15c0-1.38 1.12-2.5 2.5-2.5zm7.5 5c-2.761 0-5 2.239-5 5s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5zm0 2.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z" fill="white"/>
  </svg>
);

const GooglePayIcon = () => (
  <svg viewBox="0 0 40 40" className="w-10 h-10">
    <rect width="40" height="40" rx="8" fill="#FFFFFF"/>
    <path d="M20 7.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5 12.5-5.596 12.5-12.5S26.904 7.5 20 7.5zm-2.5 17.5c-2.761 0-5-2.239-5-5s2.239-5 5-5c1.316 0 2.516.475 3.453 1.32l-1.423 1.423c-.694-.582-1.46-.873-2.24-.873-1.655 0-3 1.345-3 3s1.345 3 3 3c1.452 0 2.683-1.022 2.945-2.394h-2.945v-1.969h5.121c.065.336.129.674.129 1.113 0 3.07-2.06 5.25-5 5.25z" fill="#4285F4"/>
    <path d="M26.75 18.75h-1.5v-1.5h-1.25v1.5h-1.5v1.25h1.5v1.5h1.25v-1.5h1.5z" fill="#34A853"/>
  </svg>
);

const OtherIcon = () => (
  <svg viewBox="0 0 40 40" className="w-10 h-10">
    <rect width="40" height="40" rx="8" fill="#E0E0E0"/>
    <path d="M12.5 12.5c-1.38 0-2.5 1.12-2.5 2.5v10c0 1.38 1.12 2.5 2.5 2.5h15c1.38 0 2.5-1.12 2.5-2.5v-10c0-1.38-1.12-2.5-2.5-2.5h-15zm0 2.5h15v2.5h-15v-2.5zm0 5h2.5v2.5h-2.5v-2.5zm5 0h5v2.5h-5v-2.5zm7.5 0h2.5v2.5h-2.5v-2.5z" fill="#757575"/>
  </svg>
);

function SuccessModal  ({ open, close }) {
  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div className="relative w-[300px] bg-white rounded-[20px] p-6 shadow-2xl">
        {/* Close Button */}
        <button 
          onClick={close} 
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
        >
          <XCircle className="w-5 h-5" />
        </button>

        {/* Success Image */}
        <div className="flex justify-center mb-4">
        <span className="text-black-600 font-bold"> Payment Success</span>
          <div className="w-16 h-16 rounded-full bg-green-100 flex items-center justify-center">
            
            <div className="w-8 h-8 border-4 border-green-500 rounded-full animate-bounce" />
          </div>
        </div>

        {/* Title and Description */}
        

        {/* Redeem Code Section */}
        

        {/* Rewards Section */}
        

        {/* OK Button */}
        <button
          onClick={close}
          className="w-full mt-6 py-3 bg-gradient-to-t from-green-600 to-green-400 
                     hover:from-green-700 hover:to-green-500 text-white font-bold 
                     rounded-full transition-all transform hover:scale-[1.02]"
        >
          OK
        </button>
      </div>
    </div>
  );
};




function DetailsModal({open, close}) {

    return (
      <div>
        <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title1"
          aria-describedby="modal-modal-description1"
        >
          <Box sx={style}>
              <CloseIcon sx={{position:"absolute", top:10, right:10, cursor:"pointer"}} onClick={close}/>
                <Typography fontSize={18} fontWeight={600} textAlign="left">Please fill the following details</Typography>
                <Box sx={{mt:3, display:"flex", flexDirection:"column", gap:"10px"}}>
                    <Box sx={{textAlign:"left"}}>
                        <Typography color="#162533" fontSize={14} mb={1}>Name</Typography>
                        <TextField id="outlined-basic" placeholder="John Doe" label="" variant="outlined"  fullWidth />
                    </Box>
                    <Box sx={{textAlign:"left"}}>
                        <Typography color="#162533" fontSize={14}  mb={1}>Mobile Number</Typography>
                        <TextField id="outlined-basic" label="" placeholder="90738267627" variant="outlined" fullWidth />
                    </Box>
                    <Box sx={{textAlign:"left"}}>
                        <Typography color="#162533" fontSize={14}  mb={1}>Email</Typography>
                        <TextField id="outlined-basic" label="" placeholder="johndoe@gmail.com" variant="outlined"  fullWidth />
                    </Box>
                </Box>
                <Button
          fullWidth
              sx={{
                background: "linear-gradient(360deg, #26CD18 0%, #74E018 100%)",
                mx: "auto",
                borderRadius: "32px",
                py: 1,
                mt:2,
                textTransform: "capitalize",
              }}
            >
              <Typography fontSize={16} fontWeight={700} color="#fff" variant="p">
                OK
              </Typography>
            </Button>
          </Box>
        </Modal>
      </div>
    );
  }

// function BasicModal({open, close}) {

//     return (
//       <div>
//         <Modal
//           open={open}
//           onClose={close}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={style}>
//               <CloseIcon sx={{position:"absolute", top:10, right:10, cursor:"pointer"}} onClick={close}/>
//             <Box sx={{display:"flex", justifyContent:"center"}}>
//               <img src={congratsImg} />
//             </Box>
//             <Typography fontWeight={600}>Congrats</Typography>
//             <Typography>Points has been added on your account</Typography>
//             <Box sx={{border: "1px dashed #ABB7C2", borderRadius:"6px", display:"flex", justifyContent:"space-between", p:1, mt:1}}>
//               <Typography sx={{alignSelf:"center", letterSpacing:"3px", color:"#A5B7C9"}} fontWeight={600}>0121DARO2</Typography>
//               <Button sx={{backgroundColor:"#26CD18", width:"100px", fontSize:"12px", color:"white"}}>Redeem</Button>
//             </Box>
  
//             <Box sx={{borderTop:"1px dashed #ABB7C2", mt:4, pt:4,}}>
//               <Box className="reward-bg" sx={{p:1,position:"relative", borderRadius:"8px", height:"60px", display:"flex", justifyContent:"space-between"}}>    
//                   <Box sx={{position:"absolute", top:"-5px", right:"-5px", zIndex:5}}>
//                       <img src={stars}/>
//                   </Box>
//                   <Box sx={{display:"flex", gap:"10px", textAlign:"left"}}>
//                       <img src={check2} style={{width:"35px", objectFit:"contain"}}/>
//                       <div>
//                           <Typography fontWeight={600} fontSize={20} color="#64DC18">1000</Typography>
//                           <Typography color="#fff" fontSize={12}>Coins Redeemed on this site</Typography>
//                       </div>
//                   </Box>
//                   <Box>
//                       <img src={coin} style={{width:"50px"}}/>
//                   </Box>
                  
                  
//               </Box>
//             </Box>
//             <Button
//           fullWidth
//               sx={{
//                 background: "linear-gradient(360deg, #26CD18 0%, #74E018 100%)",
//                 mx: "auto",
//                 borderRadius: "32px",
//                 py: 1,
//                 mt:2,
//                 textTransform: "capitalize",
//               }}
//             >
//               <Typography fontSize={16} fontWeight={700} color="#fff" variant="p">
//                 OK
//               </Typography>
//             </Button>
//           </Box>
//         </Modal>
//       </div>
//     );
//   }
  const formatTime = (seconds) => {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

  export default function VerifyDetails() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isNavigating, setIsNavigating] = useState(false);
    const orderData = location.state || {};
    const orderAmount = useSelector(selectOrderAmount);
    const displayAmount = orderAmount || orderData?.amount || 1;
    const [open, setOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
   
    const orderError = useSelector(selectOrderError);
    const { orderStatus } = useSelector(selectOrderDetails);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const paymentLinksData = useSelector(selectPaymentLinks);
    const [hasPlacedOrder, setHasPlacedOrder] = useState(false);
    const [isCheckingStatus, setIsCheckingStatus] = useState(false);
    const [orderPaymentStatus, setOrderPaymentStatus] = useState(null);
    const [orderCreatedAt, setOrderCreatedAt] = useState(null);
  const [orderLastUpdated, setOrderLastUpdated] = useState(null);
  const [cartItemsCleared, setCartItemsCleared] = useState(false);
    // New state for payment monitoring
    const [paymentTimer, setPaymentTimer] = useState(() => {
      const savedTimer = localStorage.getItem('paymentTimer');
      const savedTimestamp = localStorage.getItem('timerTimestamp');
      
      if (savedTimer && savedTimestamp) {
        const elapsed = Math.floor((Date.now() - parseInt(savedTimestamp)) / 1000);
        const remainingTime = Math.max(0, parseInt(savedTimer) - elapsed);
        return remainingTime > 0 ? remainingTime : 180;
      }
      return 180;
    });

    const [paymentLinks, setPaymentLinks] = useState({
      phonePe: "",
      paytm: "",
      gpay: "",
      other: ""
    });
  
    const { user } = useSelector((state) => state.auth);
    const { status, error } = useSelector((state) => state.order);
    const [formData, setFormData] = useState({
      username: user?.username || "Loading...",
      email: user?.email || "Loading...",
      phone: user?.phone || "Loading..."
    });
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const handlePaymentTimeout = useCallback(() => {
      setIsCheckingStatus(false);
      dispatch(clearOrderState());
      localStorage.removeItem('orderData');
      localStorage.removeItem('paymentTimer');
      localStorage.removeItem('timerTimestamp');
      localStorage.setItem('paymentStatus', 'cancelled');
      alert('Payment session expired. You will be redirected to the home page.');
      navigate('/', { replace: true });
    }, [dispatch, navigate]);
    useEffect(() => {
      const timerInterval = setInterval(() => {
        setPaymentTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timerInterval);
            handlePaymentTimeout(); // Navigate back if timer expires
            return 0;
          }
    
          const newTime = prev - 1;
    
          localStorage.setItem('paymentTimer', newTime.toString());
          localStorage.setItem('timerTimestamp', Date.now().toString());
    
          return newTime;
        });
      }, 1000); // Run every second
    
      return () => {
        clearInterval(timerInterval);
        if (!isNavigating) {
          localStorage.setItem('paymentStatus', 'cancelled');
        }
      };
    }, [handlePaymentTimeout, isNavigating]);
    useEffect(() => {
      if (orderStatus === 'Success' || orderStatus === 'success') {
        
        setSuccessOpen(true);
      }
    }, [orderStatus]);

    useEffect(() => {
      let redirectTimer;
      // Check if the success modal is open
      if (successOpen) {
        console.log("Starting 3 second redirect timer");
        redirectTimer = setTimeout(() => {
          console.log("Executing redirect");
          setSuccessOpen(false);
          dispatch(clearOrderState());
          navigate('/', { replace: true });
        }, 3000); // 3 seconds delay
      }
      return () => {
        if (redirectTimer) {
          console.log("Clearing redirect timer");
          clearTimeout(redirectTimer);
        }
      };
    }, [successOpen, dispatch, navigate]);

    const handleSuccessClose = useCallback(() => {
      // This function now only handles manual closes
      setSuccessOpen(false);
      dispatch(clearOrderState());
      navigate('/', { replace: true });
    }, [dispatch, navigate]);

    useEffect(() => {
      console.log("Order status changed:", orderStatus); // Debugging log
      if (orderStatus === 'Success' || orderStatus === 'success') {
        setSuccessOpen(true);
      }
    }, [orderStatus]);
    

    useEffect(() => {
      if (paymentTimer > 0) {
        localStorage.setItem('paymentTimer', paymentTimer.toString());
        localStorage.setItem('timerTimestamp', Date.now().toString());
      }
    }, [paymentTimer]);

    
  

   

    const handleBackToCart = useCallback(() => {
      if (isNavigating) return; // Prevent multiple triggers
    
      const shouldNavigate = window.confirm(
        'Are you sure you want to leave? Your payment session will be cancelled.'
      );
    
      if (!shouldNavigate) return;
    
      setIsNavigating(true); // Set navigating state to true
    
      // Clear data and navigate
      try {
        localStorage.removeItem('paymentTimer');
        localStorage.removeItem('timerTimestamp');
        dispatch(clearOrderState());
        localStorage.removeItem('orderData');
        navigate('/cart', { replace: true });
      } catch (error) {
        console.error('Error during navigation:', error);
      } finally {
        setIsNavigating(false); // Reset navigating state
      }
    }, [dispatch, navigate, isNavigating]);

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          const savedTimer = localStorage.getItem('paymentTimer');
          const savedTimestamp = localStorage.getItem('timerTimestamp');
    
          if (savedTimer && savedTimestamp) {
            const elapsed = Math.floor((Date.now() - parseInt(savedTimestamp)) / 1000);
            const remainingTime = Math.max(0, parseInt(savedTimer) - elapsed);
    
            if (remainingTime <= 0) {
              handlePaymentTimeout();
            } else {
              setPaymentTimer(remainingTime);
            }
          }
        }
      };
    
      document.addEventListener('visibilitychange', handleVisibilityChange);
    
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, [handlePaymentTimeout]);
    
    useEffect(() => {
      const fetchOrderStatus = async () => {
        if (!orderData.orderId) return; // Don't fetch if no orderId
    
        try {
          const response = await dispatch(getOrderStatus({
            order_id: orderData.orderId,
            decoded_status : false,
          }));
    
          if (getOrderStatus.fulfilled.match(response)) {
            const { status } = response.payload;
            
            if (status === 'success') {
              setSuccessOpen(true);
              // Clear timer and local storage
              localStorage.removeItem('paymentTimer');
              localStorage.removeItem('timerTimestamp');
              localStorage.removeItem('orderData');
              // Stop the polling
              return true;
            }
          }
          return false;
        } catch (error) {
          console.error('Error fetching order status:', error);
          return false;
        }
      };
    
      // Set up polling interval
      const intervalId = setInterval(async () => {
        const shouldStopPolling = await fetchOrderStatus();
        if (shouldStopPolling) {
          clearInterval(intervalId);
        }
      }, 2000);
    
      // Cleanup on unmount
      return () => {
        clearInterval(intervalId);
      };
    }, [dispatch, orderData.orderId]);
  
    useEffect(() => {
      return () => {
        // When component unmounts normally (not due to timeout)
        if (paymentTimer > 0) {
          localStorage.setItem('paymentStatus', 'cancelled');
        }
      };
    }, [paymentTimer]);

    useEffect(() => {
      const savedOrderData = localStorage.getItem("orderData");
      const statePaymentLinks = orderData.paymentLinks || {};
    
      if (savedOrderData) {
        const parsedData = JSON.parse(savedOrderData);
        setPaymentLinks(parsedData.paymentLinks || statePaymentLinks);
      } else if (statePaymentLinks) {
        setPaymentLinks(statePaymentLinks);
      } else {
        alert("Payment links could not be loaded. Please retry.");
        navigate("/cart");
      }
    }, [navigate, orderData.paymentLinks]);
    useEffect(() => {
      if (!orderData || !orderData.amount) {
        navigate('/cart', { replace: true });
      }
    }, [orderData, navigate]);
    
  
  

    useEffect(() => {
      if (user) {
        setFormData({
          username: user?.username || "Loading...",
          email: user?.email || "Loading...",
          phone: user?.phone || "Loading..."
        });
      } else {
        // Fetch user data if not available
        dispatch(fetchUserData());
      }
    }, [user, dispatch]);
    // useEffect(() => {
    //   dispatch(fetchUserData());
  
    //   const savedOrderData = localStorage.getItem("orderData");
  
    //   if (savedOrderData) {
    //     const parsedData = JSON.parse(savedOrderData);
  
    //     if (orderStatus === "idle") {
    //       dispatch(setOrderAmount(parsedData.amount));
    //       setPaymentLinks(parsedData.paymentLinks || {});
    //     }
    //   } else {
    //     navigate("/cart"); // Redirect to cart if no valid order data
    //   }
    // }, [dispatch, navigate, orderStatus]);
  
    
    

    const handlePlaceOrder = async (orderData) => {
      if (!selectedPayment) {
        alert("Please select a payment method");
        return;
      }
    
      if (!isTermsAccepted) {
        alert("Please accept the terms and conditions");
        return;
      }
    
      setIsLoading(true);
    
      try {
        // Get payment link based on the selected method
        const paymentUrl = getPaymentLink(paymentLinks, selectedPayment.id);
    
        if (!paymentUrl) {
          throw new Error(`${selectedPayment.name} payment link is not available.`);
        }
    
        // Redirect to the payment URL
        window.location.href = paymentUrl;
      } catch (error) {
        console.error("Payment Error:", error);
        alert(error.message || "Payment failed. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    
    // Helper function to get payment link
    const getPaymentLink = (paymentLinks, methodId) => {
      const linkMap = {
        phonepe: paymentLinks.phonePe,
        paytm: paymentLinks.paytm,
        gpay: paymentLinks.gpay,
        other: paymentLinks.other,
      };
      return linkMap[methodId] || null; // Ensure it returns null if no link is found
    };
    
    // Helper function to handle payment redirection
    const handlePaymentRedirect = (paymentUrl) => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    
      if (isMobile) {
        // For iOS devices
        if (isIOS) {
          window.location.href = paymentUrl;
        } else {
          // For Android devices, try to open app first
          const baseUrl = new URL(paymentUrl);
          const intentUrl = `intent://${baseUrl.pathname}${baseUrl.search}#Intent;scheme=${selectedPayment.id};package=com.${selectedPayment.id};end;`;
          window.location.href = intentUrl;
        }
        
        // Fallback after a delay
        setTimeout(() => {
          window.open(paymentUrl, '_blank');
        }, 2000);
      } else {
        // For desktop browsers
        window.open(paymentUrl, '_blank');
      }
    };
    
    // Helper function to monitor payment status
    const startPaymentStatusMonitoring = (orderId) => {
      setIsCheckingStatus(true);
    
      const intervalId = setInterval(async () => {
        try {
          const response = await dispatch(getOrderStatus({
            order_id: orderId,
            decoded_status: false,
          }));
    
          if (getOrderStatus.fulfilled.match(response)) {
            const { status } = response.payload;
            
            if (status === 'success' || status === 'Success') {
              clearInterval(intervalId);
              handlePaymentSuccess();
            }
          }
        } catch (error) {
          console.error('Status check error:', error);
        }
      }, 2000);
    
      // Clear interval after 3 minutes
      setTimeout(() => {
        clearInterval(intervalId);
      }, 180000);
    };
    
    // Helper function to handle successful payment
    const handlePaymentSuccess = () => {
      setSuccessOpen(true);
      localStorage.removeItem('paymentTimer');
      localStorage.removeItem('timerTimestamp');
      localStorage.removeItem('orderData');
    };
    
    // Update payment methods array
    const paymentMethods = [
      {
        id: 'phonepe',
        name: "PhonePe",
        icon: <img src={phonePayImg} alt="PhonePe" style={{ width: "32px", height: "32px" }} />,
        appPackage: 'com.phonepe.app'
      },
      {
        id: 'paytm',
        name: "Paytm",
        icon: <img src={paytmImg} alt="Paytm" style={{ width: "47px", height: "20px" }} />,
        appPackage: 'net.one97.paytm'
      },
      {
        id: 'gpay',
        name: "Google Pay",
        icon: <img src={gPayImg} alt="Google Pay" style={{ width: "32px", height: "32px" }} />,
        appPackage: 'com.google.android.apps.nbu.paisa.user'
      },
      {
        id: 'other',
        name: "Other UPI",
        icon: <img src={otherImg} alt="Other" style={{ width: "32px", height: "32px" }} />
      }
    ];
    
    // Update the payment method selection handler
    const handlePaymentMethodSelect = (method) => {
      setSelectedPayment(method);
      console.log('Selected payment method:', method);
    };
    // useEffect(() => {
    //   // Retrieve order data from localStorage and trigger placeOrder API call
    //   const savedOrderData = localStorage.getItem('orderData');
    //   if (savedOrderData) {
    //     const parsedData = JSON.parse(savedOrderData);
    
    //     // Ensure the order amount is set in Redux
    //     dispatch(setOrderAmount(parsedData.amount));
    
    //     // Call placeOrder API
    //     handlePlaceOrder(parsedData);
    //   } else {
    //     console.error('Order data missing in localStorage.');
    //   }
    // }, []); // Run
    
    const progressStyles = {
      root: {
        height: 6,
        borderRadius: 3,
        backgroundColor: '#F5F5F5', // Light grey background
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#4FCD18',
          borderRadius: 3,
          transition: 'transform 0.4s linear', // Smooth animation
        }
      }
    };
  

    
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const handleDetailsClose = () => setDetailsOpen(false);
  
    return (
      <>
        {/* <BasicModal open={open} close={handleClose} /> */}
        <DetailsModal open={detailsOpen} close={handleDetailsClose} />
        <SuccessModal open={successOpen} close={handleSuccessClose} />
        <Card
          elevation={0}
          sx={{
            boxShadow: "0px 4px 18.8px -5px #50A63B59",
            borderRadius: "25px",
            mt: 4,
            width: "450px",
            mx: "auto",
            "@media (max-width:768px)": {
              width: "90%",
              boxShadow: "none",
              mt: 0,
            },
          }}
        >
          <Box
            sx={{
              p: 5,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              "@media (max-width:768px)": {
                p: 3,
              },
            }}
          >

<Box
  onClick={!isNavigating ? handleBackToCart : undefined}
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: isNavigating ? 'not-allowed' : 'pointer',
    marginBottom: 2,
    opacity: isNavigating ? 0.6 : 1, // Reduce opacity during buffering
  }}
>
  {isNavigating ? (
    <CircularProgress size={20} sx={{ color: '#4FCD18', mr: 1 }} />
  ) : (
    <ArrowBackIcon />
  )}
  <Typography fontSize={14} fontWeight={600} ml={1}>
    Back to Cart
  </Typography>
</Box>
        {/* {isCheckingStatus && (
            <Box sx={{ mb: 2, textAlign: "center" }}>
              <Typography variant="h6" color="primary">
                Payment in Progress
              </Typography>
              <Typography color="error">Time remaining: {formatTime(paymentTimer)}</Typography>
              <LinearProgress
                variant="determinate"
                value={(paymentTimer / 180) * 100}
                sx={{
                  ...progressStyles.root,
                  "& .MuiLinearProgress-bar": progressStyles.bar,
                  mt: 1
                }}
              />
            </Box> */}
            <Box 
  sx={{ 
    mb: 2.5,
    textAlign: 'center',
    maxWidth: '100%',
  }}
>
  <Typography 
    sx={{
      fontSize: '18px',
      color: '#4fcd18', // Dark text color for "Complete Payment"
      fontWeight: 600,
      mb: 1
    }}
  >
    Complete Payment
  </Typography>
  <Typography 
    sx={{
      color: '#728393', // Subtle grey for "Time remaining"
      fontSize: '14px',
      mb: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
      '& span': {
        color: '#F44336', // Keep timer in red but slightly muted
        fontWeight: 500
      }
    }}
  >
    Time remaining: <span>{formatTime(paymentTimer)}</span>
  </Typography>
  <Box sx={{ px: 0.5 }}> {/* Slight padding for progress bar */}
    <LinearProgress 
      variant="determinate" 
      value={(paymentTimer / 180) * 100} 
      sx={progressStyles.root}
    />
  </Box>
</Box>
 
            <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography fontWeight={600} sx={{ "@media (max-width:768px)": { fontSize: 22 } }}>
                Verify your details
              </Typography>
              <Typography fontSize={14} fontWeight={600}>
                Your voucher will be sent to you on
              </Typography>
              <Typography color="#64B142" fontWeight={600} fontSize={12}>
                SMS, Whatsapp, Email
              </Typography>
            </Box>
            {/* Editable Details Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#F1F1F1",
                  p: 2,
                  flexGrow: "1",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography fontSize={12} color="#728393">Name</Typography>
                  <Box>
                    <BorderColorIcon htmlColor="#4FCD18" onClick={() => setDetailsOpen(true)} />
                  </Box>
                </Box>
                <Typography fontWeight={600}>{formData.username}</Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#F1F1F1",
                  p: 2,
                  flexGrow: "1",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography fontSize={12} color="#728393">Phone No.</Typography>
                  <Box>
                    <BorderColorIcon htmlColor="#4FCD18" onClick={() => setDetailsOpen(true)} />
                  </Box>
                </Box>
                <Typography fontWeight={600}>{formData.phone}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                textAlign: "left",
              }}
            >
              <Box
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#F1F1F1",
                  p: 2,
                  flexGrow: "1",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography fontSize={12} color="#728393">Email</Typography>
                  <Box>
                    <BorderColorIcon htmlColor="#4FCD18" onClick={() => setDetailsOpen(true)} />
                  </Box>
                </Box>
                <Typography fontWeight={600}>{formData.email}</Typography>
              </Box>
            </Box>
            <FormControlLabel
  control={
    <Checkbox
      checked={isTermsAccepted}
      onChange={(e) => setIsTermsAccepted(e.target.checked)}
      color="primary"
      size="small"
    />
  }
  sx={{ 
    '& .MuiFormControlLabel-label': { 
      fontSize: '10px',
      color: '#728393'
    }
  }}
  label="I accept Terms and Conditions and allow redeeming 1000 coins to this site after payment."
/>
            {/* Payment Methods */}
            <Box sx={{ mt: 2 }}>
  <Typography fontWeight={600} textAlign="left" mb={2}>
    Payment Method
  </Typography>
  
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: "8px",
      mb: 2
    }}
  >
    {paymentMethods.map((method) => (
      <Box
        key={method.id}
        onClick={() => handlePaymentMethodSelect(method)}
        sx={{ 
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4px"
        }}
      >
        <Box
          sx={{
            width: "64px",
            height: "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: selectedPayment?.id === method.id ? "#F0F7FF" : "#F5F5F5",
            borderRadius: "12px",
            border: selectedPayment?.id === method.id ? "2px solid #4FCD18" : "1px solid #EAEAEA",
            transition: "all 0.2s ease",
            '&:hover': {
              backgroundColor: "#F0F7FF",
              transform: "scale(1.05)"
            }
          }}
        >
          {method.icon}
        </Box>
        <Typography 
          fontSize="12px"
          color="#666"
          textAlign="center"
          sx={{
            color: selectedPayment?.id === method.id ? "#4FCD18" : "#666",
            fontWeight: selectedPayment?.id === method.id ? 600 : 400
          }}
        >
          {method.name}
        </Typography>
      </Box>
    ))}
  </Box>
</Box>

          {/* Make Payment Button */}
          <Button
            onClick={() => handlePlaceOrder(orderData)}
            disabled={isLoading}
            fullWidth
            sx={{
              background: isLoading 
                ? "#ccc" 
                : "linear-gradient(360deg, #26CD18 0%, #74E018 100%)",
              mx: "auto",
              borderRadius: "32px",
              py: 1,
              mt: 2,
              textTransform: "capitalize",
              "@media (max-width:768px)": { mt: 10 },
            }}
          >
            <Typography fontSize={16} fontWeight={700} color="#fff" variant="p">
              {isLoading ? "Processing..." : `₹ ${displayAmount}.00 | Make Payment`}
            </Typography>
          </Button>
        </Box>
        </Card>
      </>
    );
  }

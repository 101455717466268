import React, { useState, useEffect,useCallback,useRef } from "react";
import banner from "../assets/banner.png";
import ReactPaginate from 'react-paginate';
import Layout from "./common/Layout";
import axios from "axios";
import { fetchProducts, selectAllProducts,selectProductError  } from '../redux/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../redux/categorySlice';
import _ from 'lodash';
import { addToCart ,updateCartItemQuantityLocally} from '../redux/cartSlice';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import ShoppingCart from './ShoppingCart';

import { clearProducts } from '../redux/productSlice';
export default function LandingPage() {
  const [showCart, setShowCart] = useState(false); 
  const initialized = useRef(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const status = useSelector((state) => state.categories.status);
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(9999);
  const [prevPageUrl, setPrevPageUrl] = useState(null);  // Add this
  const [showPopup, setShowPopup] = useState(false);
  const [deliveryType, setDeliveryType] = useState("delayed");
  const [denominationType, setDenominationType] = useState("fixed");
  const [quantities, setQuantities] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategories, setSelectedSubCategories,updatedSubCategories] = useState([]);
  const [appendMode, setAppendMode] = useState(false); 
  const [loading, setLoading] = useState(false);
  const [showAllSubCategories, setShowAllSubCategories] = useState(false);
  const [previousPage, setPreviousPage] = useState(1);
  const [page, setPage] = useState(1);  // Page state for pagination
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [hasMore, setHasMore] = useState(true);  // Check if more products are available
  const perPage = 8;  // Number of products per page
  const [showCompleteMessage, setShowCompleteMessage] = useState(false); 
  const [currentPage, setCurrentPage] = useState(1);
  const products = useSelector(selectAllProducts); 
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1); // To keep track of total pages
  const categories = useSelector((state) => state.categories.categories); 
   // FIX: Fetch products from Redux store
  const productStatus = useSelector((state) => state.products.status);
  const [productsToShow, setProductsToShow] = useState(8);  // Set initial products to show
  const [zoomedProduct, setZoomedProduct] = useState(null); 
  const subcategories = useSelector((state) => state.categories.subcategories);
  const [subCategoryLimit, setSubCategoryLimit] = useState(5); 
  const error = useSelector(selectProductError);
  const [selectedDenominations, setSelectedDenominations] = useState({});
  const [selectedDenomination, setSelectedDenomination] = useState(0);
  const [quantity, setQuantity] = useState(1);
  
  const [denominationErrors, setDenominationErrors] = useState({});
  // Fetch products when filters are applied
  const navigate = useNavigate();
  
  
  useEffect(() => {
    const initializeData = async () => {
      if (initialized.current) return;
      
      try {
        initialized.current = true;
        setLoading(true);
        
        await dispatch(fetchCategories());
        
        const response = await dispatch(fetchProducts({
          page: 1,
          perPage,
          categoryId: null,
          subcategoryIds: [],
          priceMin,
          priceMax,
          append: false
        })).unwrap();

        setHasMore(response.hasMore);
        setProductsToShow(perPage);
      } catch (error) {
        console.error('Error initializing data:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, []);


  const handleViewCoupon = (product) => {
    const selectedDenomination = selectedDenominations[product.id] || product.denominations[0].min;
    navigate("/shopping-cart", { 
      state: { 
        product: {
          ...product,
          selectedDenomination: selectedDenomination,
          quantity: quantities[product.id] || 1,
          
        }
      } 
    });
  };
  const handleQuantityChange = (productId, newQuantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
    
    // Update in Redux as well, if needed
    dispatch(updateCartItemQuantityLocally({
      id: productId,
      quantity: newQuantity,
      to_pay: selectedDenominations[productId] * newQuantity,
    }));
  };
  


  const handleClosePopup = () => {
    setShowCart(false);  // Hide the popup
  };


  const fetchFilteredProducts = useCallback(() => {
    if (loading) return;
    
    setLoading(true);
    
    const params = {
      categoryId: selectedCategory?.id || null,
      subcategoryIds: selectedSubCategories.length ? selectedSubCategories : [],
      page,
      priceMin,
      priceMax,
      limit: productsToShow,
      append: page > 1,
    };
  
    return dispatch(fetchProducts(params))
      .unwrap()
      .then(response => {
        setHasMore(!!response.next);
        setTotalPages(Math.ceil(response.count / perPage));
        setShowCompleteMessage(!response.next);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    selectedCategory?.id,
    selectedSubCategories,
    page,
    priceMin,
    priceMax,
    productsToShow,
    loading,
    dispatch
  ]);

  
  const handleShowMore = async () => {
    if (loading || !hasMore) return;

    try {
      setLoading(true);
      const nextPage = page + 1;
      
      const response = await dispatch(fetchProducts({
        categoryId: selectedCategory?.id || null,
        subcategoryIds: selectedSubCategories,
        page: nextPage,
        perPage,
        priceMin,
        priceMax,
        append: true
      })).unwrap();

      setPage(nextPage);
      setProductsToShow(prev => prev + perPage);
      setHasMore(response.hasMore);
    } catch (error) {
      console.error('Error loading more products:', error);
    } finally {
      setLoading(false);
    }
  };

// 2. Update handleShowLess
const handleShowLess = useCallback(() => {
  dispatch(clearProducts());
  setPage(1);
  setProductsToShow(perPage);
  setHasMore(true);
  
  dispatch(fetchProducts({
    categoryId: selectedCategory?.id || null,
    subcategoryIds: selectedSubCategories,
    page: 1,
    perPage,
    priceMin,
    priceMax,
    append: false
  }));
}, [selectedCategory?.id, selectedSubCategories, priceMin, priceMax]);
  
 


const toggleShowAllSubCategories = () => {
  if (!showAllSubCategories) {
    setSubCategoryLimit(subcategories.length);  // Show all subcategories
  } else {
    setSubCategoryLimit(5);  // Show only 5 subcategories
  }
  setShowAllSubCategories(!showAllSubCategories);  // Toggle the boolean state
};





// Handle subcategory selection



useEffect(() => {
  if (!initialized.current) return;

  const applyFilters = async () => {
    try {
      setLoading(true);
      setPage(1);
      
      // Clear products before fetching with new filters
      dispatch(clearProducts());
      
      const response = await dispatch(fetchProducts({
        categoryId: selectedCategory?.id || null,
        subcategoryIds: selectedSubCategories,
        page: 1,
        perPage,
        priceMin,
        priceMax,
        append: false
      })).unwrap();

      setHasMore(response.hasMore);
      setProductsToShow(perPage);
    } catch (error) {
      console.error('Error applying filters:', error);
    } finally {
      setLoading(false);
    }
  };
  const timeoutId = setTimeout(applyFilters, 300);
  return () => clearTimeout(timeoutId);
}, [selectedCategory?.id, selectedSubCategories, priceMin, priceMax]);



const handleSubCategoryChange = useCallback((subcategoryId) => {
  setSelectedSubCategories(prev => {
    const alreadySelected = prev.includes(subcategoryId);
    return alreadySelected
      ? prev.filter(id => id !== subcategoryId)
      : [...prev, subcategoryId];
  });
}, []);
// Function to handle previous tab click
const handlePreviousTabClick = () => {
  // Check if we have a valid previous page and we are not already on the first page
  if (currentPage > 1) {
    const newPage = currentPage - 1; // Navigate to the previous page

    // Set the current page to the previous page
    setCurrentPage(newPage);
    setHasMore(true);  // Reset `hasMore` to fetch more products
    setAppendMode(false);  // Ensure append mode is off

    // Dispatch action to fetch products for the previous page
    dispatch(fetchProducts({
      subcategoryIds: selectedSubCategories.length ? selectedSubCategories : [], // Pass subcategories
      categoryId: selectedCategory ? selectedCategory.id : null,
      page: newPage, // Set the page to the previous one
    }));

    // Update previous page only if there is a valid previous page
    setPreviousPage(newPage > 1 ? newPage - 1 : 1);
  }
};
// Toggle the append mode for product loading


// Clear all filters, reset the state, and clear the product list
const handleClearFilters = useCallback(() => {
  setSelectedSubCategories([]);
  setSelectedCategory(null);
  setPriceMin(0);
  setPriceMax(9999);
  setPage(1);
  setHasMore(true);
}, []);



const handleDenominationChange = (productId, enteredValue, isManual = false) => {
  
  const numericValue = Number(enteredValue);
  
  
  const product = products.find(product => product.id === productId);

  if (!product) {
   
    return;
  }

  // Retrieve min and max from the product denomination
  const minDenomination = product.denominations[0]?.min || 0;
  const maxDenomination = product.denominations[0]?.max || 0;

  // If it's a manual field, validate the value against both global and product-specific limits
  if (isManual) {
    // Ensure the entered value falls within both the global price range and the product's denomination range
    if (numericValue < minDenomination) {
      setDenominationErrors(prevErrors => ({
        ...prevErrors,
        [productId]: `Value is less than the minimum allowed amount of ${minDenomination}`
      }));
      return;
    }

    if (numericValue > maxDenomination) {
      setDenominationErrors(prevErrors => ({
        ...prevErrors,
        [productId]: `Exceeds the maximum allowed amount of ${maxDenomination}`
      }));
      return;
    }

    if (numericValue < priceMin || numericValue > priceMax) {
      setDenominationErrors(prevErrors => ({
        ...prevErrors,
        [productId]: `Value must be between global price range ${priceMin} and ${priceMax}`
      }));
      return;
    }

    // If all checks pass, clear the error message
    setDenominationErrors(prevErrors => ({
      ...prevErrors,
      [productId]: ""  // Clear any existing error for this product
    }));
  }

  // Update the selected denominations with the new valid value
  if (!isNaN(numericValue)) {
    setSelectedDenominations(prevDenominations => ({
      ...prevDenominations,
      [productId]: numericValue
    }));
    
    // Dispatch action to update cart item denomination in Redux store
    // dispatch(updateCartItemQuantity({ productId, denomination: numericValue }));

   
  }
};
const toggleSidebar = () => {
  setIsOpen(!isOpen); // Toggle sidebar visibility
};
const [isOpen, setIsOpen] = useState(false);
const fliteredSubCategories = showAllSubCategories ? subCategories : subCategories.slice(0, 5);
const handleNextPage = () => {
  if (hasMore) {
    setPage((prevPage) => prevPage + 1);
  }
};

const handlePrevPage = () => {
  if (page >= 1) {
    setPage((prevPage) => prevPage - 1);  // Move back one page
  }
};

  
  return (
    <Layout>
<div className="min-h-screen bg-gray-50">
  
      {/* Mobile Filter Button */}
      <div className="sticky top-0 z-30 bg-white md:hidden">
        <button
          className="w-full p-4 flex items-center justify-between bg-white shadow-sm"
          onClick={toggleSidebar}
        >
          <span className="font-medium">Filters</span>
          {isOpen ? (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </button>
      </div>

      <div className="flex flex-col md:flex-row relative">
        {/* Sidebar Filters - Fixed on desktop */}
        <div
          className={`fixed md:sticky md:block w-full md:w-64 h-[calc(100vh-4rem)] md:h-[calc(100vh-0rem)] bg-white z-20 transition-all duration-300 ease-in-out transform ${
            isOpen ? 'translate-x-0 top-16' : '-translate-x-full md:translate-x-0'
          } md:top-0 overflow-hidden`}
        >
          {/* Scrollable container for filter content */}
          <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div className="p-4 space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-bold">Filters</h2>
                <button className="text-sm hover:underline" onClick={handleClearFilters}>
                  Clear All
                </button>
              </div>

              {/* Categories Filter */}
              <div className="border rounded p-4">
                <h3 className="text-lg font-semibold mb-2">Categories</h3>
                <ul className="space-y-2">
                  {subcategories.slice(0, subCategoryLimit).map(subCategory => (
                    <li key={subCategory.id} className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedSubCategories.includes(subCategory.id)}
                        onChange={() => handleSubCategoryChange(subCategory.id)}
                      />
                      <label className="text-sm">{subCategory.name}</label>
                    </li>
                  ))}
                  {subcategories.length > 5 && (
                    <li>
                      <button
                        className="text-gray-500 text-sm hover:underline"
                        onClick={toggleShowAllSubCategories}
                      >
                        {showAllSubCategories ? 'Show Less' : 'Show More'}
                      </button>
                    </li>
                  )}
                </ul>
              </div>

              {/* Amount Range Filter */}
              <div className="border rounded p-4">
                <h3 className="text-lg font-semibold mb-2">Amount Range</h3>
                <div className="space-y-3">
                  <div>
                    <label htmlFor="min-amount" className="block text-sm mb-1">Min:</label>
                    <input
                      type="number"
                      id="min-amount"
                      className="w-full border rounded px-3 py-2 text-sm"
                      value={priceMin}
                      onChange={(e) => setPriceMin(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="max-amount" className="block text-sm mb-1">Max:</label>
                    <input
                      type="number"
                      id="max-amount"
                      className="w-full border rounded px-3 py-2 text-sm"
                      value={priceMax}
                      onChange={(e) => setPriceMax(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Main Content */}
        <div className="flex-1 min-h-screen">
          {/* Sort Dropdown */}
          <div className="p-4 bg-white sticky top-0 md:top-0 z-10 shadow-sm">
            <div className="flex justify-end items-center">
              <label htmlFor="sortby" className="text-sm mr-2">Sort By:</label>
              <select id="sortby" className="text-sm border rounded p-2">
                <option value="recommended">Recommended</option>
                <option value="price_high_low">Price: High to Low</option>
                <option value="price_low_high">Price: Low to High</option>
              </select>
            </div>
          </div>

          {/* Banner */}
          <div className="px-4 md:px-8">
            <img src={banner} alt="Gift Card Banner" className="w-full h-auto object-cover rounded-lg" />
          </div>

          {/* Products Grid */}
          <div className="p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Array.isArray(products) && products.length > 0 ? (
                products.slice(0, productsToShow).map((product) => (
                  <div key={product.id} className="bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300">
                    <div className="p-4">
                      <img
                        src={product.image_url || '/placeholder.svg'}
                        className="w-full h-32 object-cover rounded-lg mb-4"
                        alt={product.name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/placeholder.svg';
                        }}
                      />
                      
                      <div className="flex flex-wrap gap-2 mb-3">
                        <span className="text-xs py-1 px-2 rounded-full bg-orange-100 text-orange-600">
                          Recommended
                        </span>
                        <span className="text-xs py-1 px-2 rounded-full bg-gray-100 text-gray-800">
                          Instant
                        </span>
                      </div>

                      <h5 className="font-medium text-gray-900 mb-2 h-12 overflow-hidden">
                        {product.name}
                      </h5>
                      <p className="text-sm text-gray-500 mb-4">Voucher</p>

                      <div className="space-y-3">
                        <div className="flex gap-2">
                        <div className="flex-grow min-w-0">
  {product.denominations.length > 1 ? (
    <div className="relative">
      <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 text-sm pointer-events-none">
        INR
      </span>
      <select
        className="w-full pl-12 pr-6 py-2 border rounded text-sm appearance-none bg-white truncate focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
        value={selectedDenominations[product.id] || product.denominations[0].min}
        onChange={(e) => handleDenominationChange(product.id, e.target.value, false)}
      >
        {product.denominations.map((denom) => (
          <option key={denom.id} value={denom.min} className="py-1">
            {denom.min}
          </option>
        ))}
      </select>
      {/* Custom dropdown arrow */}
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
    </div>
  ) : (
    <div className="relative">
      <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 text-sm pointer-events-none">
        INR
      </span>
      <input
        type="number"
        className="w-full pl-12 pr-2 py-2 border rounded text-sm focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
        value={selectedDenominations[product.id] || product.denominations[0].min}
        onChange={(e) => handleDenominationChange(product.id, e.target.value, true)}
        min={product.denominations[0].min}
        max={product.denominations[0].max}
      />
    </div>
  )}
</div>
                          
                          <input
                            type="number"
                            className="w-20 px-2 py-2 border rounded text-sm"
                            value={quantities[product.id] || 1}
                            onChange={(e) => handleQuantityChange(product.id, Number(e.target.value))}
                            min="1"
                          />
                        </div>

                        {denominationErrors[product.id] && (
                          <p className="text-red-500 text-xs">
                            {denominationErrors[product.id]}
                          </p>
                        )}

                        <button
                          onClick={() => handleViewCoupon(product)}
                          className="w-full py-2 px-4 border-2 border-green-500 text-green-500 rounded-lg hover:bg-green-500 hover:text-white transition-colors duration-300 text-sm font-medium"
                        >
                          View Coupon
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-full text-center py-8 text-gray-500">
                  No Products available
                </div>
              )}
            </div>

            {/* Loading State */}
            {loading && (
              <div className="flex justify-center py-8">
                <img src={LoadingSpinner} alt="Loading..." className="w-16 h-16" />
              </div>
            )}

            {/* Pagination Buttons */}
            <div className="flex justify-center gap-4 mt-8">
              {products.length > 0 && hasMore && (
                <button
                  onClick={handleShowMore}
                  className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-300"
                >
                  Show More
                </button>
              )}
              {products.length > 8 && (
                <button
                  onClick={handleShowLess}
                  className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors duration-300"
                >
                  Show Less
                </button>
              )}
            </div>

            {!hasMore && (
              <div className="text-center py-8">
                <p className="text-gray-500 text-sm">All items are loaded!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};


import { toast } from 'react-toastify'; // If using a library like react-toastify

export const showErrorNotification = (message) => {
  toast.error(message || 'An unexpected error occurred!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};